function fillFormData(formData: FormData, data: Record<string, any>, prefix = ''): void {
  Object.keys(data).forEach(function (key) {
    if (data[key] === null) {
      const formDataKey = prefix.length > 0 ? `[${key}]` : key;
      formData.append(`${prefix}${formDataKey}`, data[key]);
    } else if (typeof data[key] === 'object' && !(data[key] instanceof Blob)) {
      const newPrefix = prefix.length > 0 ? `${prefix}[${key}]` : key;
      fillFormData(formData, data[key], newPrefix);
    } else {
      const formDataKey = prefix.length > 0 ? `[${key}]` : key;
      formData.append(`${prefix}${formDataKey}`, data[key]);
    }
  });
}

export default function makeRequest(
  method: string,
  url: string,
  data: Record<string, any> | null,
  isMultipart = false,
  headers = new Headers(),
  credentials: RequestCredentials = 'same-origin',
  mode: string
): Request {
  let body = null;
  if (isMultipart && data) {
    body = new FormData();
    fillFormData(body, data);
  } else {
    if (data) {
      body = JSON.stringify(data);
    }
    headers.append('Content-Type', 'application/json');
  }
  const requestParams = {method, body, headers, credentials};

  const urlObj = new URL(url);
  urlObj.searchParams.append('mode', mode);

  return new Request(urlObj, requestParams);
}
