import {Project} from 'interfaces/Project';
import generatePdfFilename from './generatePdfFilename';

export default function downloadBlob(blob: Blob, editableProject: Project): void {
  const a = document.createElement('a');
  a.href = URL.createObjectURL(blob);
  a.download = generatePdfFilename(editableProject.name);
  a.target = '_blank';
  a.click();
}
