import {ReactElement} from 'react';
import ApiSuspense from 'components/shared/ApiSuspense';
import {Cable} from 'interfaces/Project';
import AddCableDialog from './AddCableDialog';
import useGetCableManufacturers from './hooks/useGetCableManufacturers';

type Props = {
  defaultManufacturer?: string;
  deleteTrayFromSection: () => void;
  hasSelectedTray: boolean;
  onCableSelect: (cable: Cable) => void;
};

export default function AddCableDialogContainer({
  defaultManufacturer,
  deleteTrayFromSection,
  hasSelectedTray,
  onCableSelect
}: Props): ReactElement {
  const {
    apiQueryStatus: getCableManufacturersRequestStatus,
    apiResponse: getCableManufacturersResponse
  } = useGetCableManufacturers();

  return (
    <ApiSuspense apiQueryStatus={[getCableManufacturersRequestStatus]}>
      {() => (
        <AddCableDialog
          defaultManufacturer={defaultManufacturer}
          deleteTrayFromSection={deleteTrayFromSection}
          hasSelectedTray={hasSelectedTray}
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          manufacturers={getCableManufacturersResponse!.data}
          onCableSelect={onCableSelect}
        />
      )}
    </ApiSuspense>
  );
}
