import {ChangeEvent, ReactElement} from 'react';
import AddBoxIcon from '@mui/icons-material/AddBox';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import {Alert, Box, Typography, IconButton, TextField, Button, Tooltip} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {Project} from 'interfaces/Project';
import {System} from 'interfaces/System';
import {UseConfiguratorActions} from 'views/Calculator/hooks/useConfigurator/useConfigurator';
import {UseSaveProject} from 'views/Calculator/hooks/useConfigurator/useSaveProject';
import ProjectHeader from './components/ProjectHeader';
import ProjectSections from './components/ProjectSections';

type Props = {
  configuratorActions: UseConfiguratorActions;
  isGeneratingPdf: boolean;
  onGeneratePdf: () => void;
  onProjectAction: () => void;
  project: Project;
  saveProject: UseSaveProject;
  systems: System[];
};

export default function ProjectConfiguratorView({
  configuratorActions,
  isGeneratingPdf,
  onGeneratePdf,
  onProjectAction,
  project,
  saveProject,
  systems
}: Props): ReactElement {
  const {t} = useTranslation('common');

  function handleAddSection() {
    configuratorActions.addSection(t('common:sectionIndex', {index: project.sections.length + 1}));
  }

  return (
    <Box>
      <ProjectHeader
        saveProject={saveProject}
        project={project}
        onProjectAction={onProjectAction}
      />
      <Box sx={{p: 2}}>
        {project.hasDeprecatedInfo && (
          <Box sx={{mb: 4}}>
            <Alert severity="info" onClose={() => configuratorActions.markDepecreatedAlertAsView()}>
              {t('common:projectHasDeprecatedInfo')}
            </Alert>
          </Box>
        )}
        <Box sx={{mb: 4}}>
          <TextField
            id="projectName"
            label={t('common:name')}
            value={project.name}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              configuratorActions.updateProjectName(event.target.value)
            }
            fullWidth
          />
        </Box>
        <Box sx={{mb: 4}}>
          <TextField
            id="projectDescription"
            label={t('common:description')}
            value={project.description ?? ''}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              configuratorActions.updateProjectDescription(event.target.value)
            }
            multiline
            fullWidth
            rows={5}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          py: 2,
          px: 2,
          bgcolor: 'grey.200'
        }}
      >
        <Box>
          <Typography variant="h3" gutterBottom>
            {t('common:selectCablesAndTrays')}
          </Typography>
          <Typography>{t('common:sectionsDefinition')}</Typography>
        </Box>
        <Tooltip title={t('common:addSection')}>
          <IconButton size="large" aria-label="add" onClick={handleAddSection}>
            <AddBoxIcon fontSize="large" />
          </IconButton>
        </Tooltip>
      </Box>
      {project.sections.length > 0 && (
        <Box sx={{my: 2, px: 2}}>
          <ProjectSections
            configuratorActions={configuratorActions}
            systems={systems}
            sections={project.sections}
          />
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          my: 2,
          px: 2
        }}
      >
        <Button
          variant="contained"
          size="large"
          disabled={isGeneratingPdf}
          startIcon={<PictureAsPdfIcon fontSize="small" />}
          onClick={() => onGeneratePdf()}
        >
          {isGeneratingPdf ? t('common:generatingPdf') : t('common:generatePdf')}
        </Button>
      </Box>
    </Box>
  );
}
