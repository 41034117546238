type Params<Key extends string = string> = {
  readonly [key in Key]: string | undefined;
};

function invariant(cond: any, message: string): asserts cond {
  if (!cond) throw new Error(message);
}

function generatePath(path: string, params: Params = {}): string {
  return (
    path
      .replace(/:(\w+)/g, (_, key) => {
        invariant(params[key] !== null, `Missing ":${key}" param`);
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return params[key]!;
      })
      // eslint-disable-next-line eqeqeq
      .replace(/\/*\*$/, (_) => (params['*'] == null ? '' : params['*'].replace(/^\/*/, '/')))
  );
}

function convert(searchParams: Record<string, string | number>): Record<string, string> {
  const obj: Record<string, string> = {};
  for (const property in searchParams) {
    obj[property] = `${searchParams[property]}`;
  }
  return obj;
}

export default function generatePathWithQueryParams(
  path: string,
  params?: Params,
  searchParams: Record<string, string | number> | null = null
): string {
  const generatedPath = generatePath(path, params);
  const urlSearchParams = searchParams ? new URLSearchParams(convert(searchParams)) : null;
  return urlSearchParams ? `${generatedPath}?${urlSearchParams.toString()}` : generatedPath;
}
