import {MouseEvent, ReactElement} from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  DialogActions,
  Button,
  Snackbar,
  Alert,
  Tooltip
} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {Section} from 'interfaces/Project';
import SelectSection from './components/SelectSection';

type Props = {
  sections: Section[];
  opened: boolean;
  snackbarOpened: boolean;
  onOpenClick: (event: MouseEvent) => void;
  onClose: () => void;
  onCancelClick: (event: MouseEvent) => void;
  onCopyClick: () => void;
  selectedSectionId: string;
  onSectionSelect: (to: string) => void;
  onSnackbarClose: () => void;
};

export default function CopyCablesToSectionView({
  opened,
  onClose,
  snackbarOpened,
  sections,
  selectedSectionId,
  onOpenClick,
  onCancelClick,
  onCopyClick,
  onSectionSelect,
  onSnackbarClose
}: Props): ReactElement {
  const {t} = useTranslation('common');

  return (
    <>
      <Tooltip title={t('common:copyCablesToSection.button')}>
        <IconButton aria-label="copy" onClick={onOpenClick}>
          <ContentCopyIcon />
        </IconButton>
      </Tooltip>
      <Snackbar onClose={onSnackbarClose} open={snackbarOpened} autoHideDuration={6000}>
        <Alert severity="success" sx={{width: '100%'}}>
          {t('common:copyCablesToSection.success')}
        </Alert>
      </Snackbar>
      <Dialog open={opened} maxWidth="lg" fullWidth onClose={onClose}>
        <DialogTitle>{t('common:copyCablesToSection.dialog.title')}</DialogTitle>
        <DialogContent>
          <SelectSection
            sections={sections}
            onChange={onSectionSelect}
            selectedSectionId={selectedSectionId}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancelClick} color="secondary">
            {t('common:cancel')}
          </Button>
          <Button onClick={onCopyClick} variant="contained">
            {t('common:copy')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
