/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {ReactElement, Suspense, useCallback, useState} from 'react';
import {Box, Grid, Skeleton} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {useQueryClient} from 'react-query';
import {CABLES, CABLE_MANUFACTURERS} from 'const/backend';
import {Cable, CableManufacturer} from 'interfaces/Project';
import CableForm from './components/CableForm';
import CablesTable from './components/CablesTable';
import CreateCableButton from './components/CreateCableButton';
import SelectManufacturer from './components/SelectManufacturer';

type CableFormState = {
  cable: Cable | null;
  isVisible: boolean;
};

type Props = {
  defaultManufacturer?: string;
  manufacturers: [CableManufacturer, ...CableManufacturer[]];
  onCableSelect: (cable: Cable) => void;
};

export default function SelectCable({
  defaultManufacturer,
  manufacturers,
  onCableSelect
}: Props): ReactElement {
  const {t} = useTranslation('common');
  const [selectedManufacturer, setSelectedManufacturer] = useState(() =>
    defaultManufacturer
      ? manufacturers.find((m) => m.name === defaultManufacturer) ?? manufacturers[0]
      : manufacturers[0]
  );
  const [cableForm, setCableForm] = useState<CableFormState>({
    isVisible: false,
    cable: null
  });

  const queryClient = useQueryClient();

  function handleManufacturerSelected(manufacturer: CableManufacturer) {
    setSelectedManufacturer(manufacturer);
  }

  function showCableForm() {
    setCableForm({
      isVisible: true,
      cable: null
    });
  }

  function handleGoBack() {
    setCableForm({
      isVisible: false,
      cable: null
    });
  }

  const handleCableEdit = useCallback(function (cable: Cable) {
    setCableForm({
      isVisible: true,
      cable: cable
    });
  }, []);

  function handleCreateCableSuccess(cable: Cable) {
    queryClient.invalidateQueries([CABLES]);
    queryClient.invalidateQueries([CABLE_MANUFACTURERS]);
    onCableSelect(cable);
  }

  return (
    <Box mt={2}>
      {!cableForm.isVisible && (
        <>
          <Box mb={2}>
            <Grid container spacing={2}>
              <Grid item lg={10}>
                <SelectManufacturer
                  manufacturers={manufacturers}
                  onManufacturerSelected={handleManufacturerSelected}
                  selectedManufacturer={selectedManufacturer}
                />
              </Grid>
              <Grid item lg={2}>
                <CreateCableButton onClick={showCableForm} t={t} />
              </Grid>
            </Grid>
          </Box>
          <Suspense
            fallback={
              <Box>
                {Array(10)
                  .fill(0)
                  .map((n, index) => (
                    <Skeleton
                      key={index}
                      height={50}
                      variant="rectangular"
                      sx={{mb: 1, borderRadius: 1}}
                    />
                  ))}
              </Box>
            }
          >
            <CablesTable
              manufacturer={selectedManufacturer}
              onCableClick={onCableSelect}
              onCableEdit={handleCableEdit}
              t={t}
            />
          </Suspense>
        </>
      )}
      {cableForm.isVisible && (
        <CableForm
          onSuccess={handleCreateCableSuccess}
          onGoBack={handleGoBack}
          cable={cableForm.cable}
        />
      )}
    </Box>
  );
}
