import {SYSTEMS} from 'const/backend';
import {ONE_HOUR_IN_MS} from 'const/time';
import useApiQuery, {UseApiQuery} from 'hooks/useApiQuery';
import {System} from 'interfaces/System';
import generatePathWithQueryParams from 'utils/generatePathWithQueryParams';

export default function useSystems(): UseApiQuery<System[]> {
  const path = generatePathWithQueryParams(SYSTEMS);
  return useApiQuery({
    queryOptions: {
      queryKey: [path],
      staleTime: ONE_HOUR_IN_MS
    },
    requestOptions: {
      method: 'GET',
      path
    }
  });
}
