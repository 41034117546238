export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export const AUTHENTICATE = '/authenticate';
export const REFRESH_TOKEN = '/token/refresh';
export const PROJECTS = '/projects';
export const PROJECT = '/projects/:id';
export const PROJECT_PDF = '/projects/:id/pdf';
export const SYSTEMS = '/tray-systems';
export const FINISHES = '/finishes';
export const TRAYS = '/trays';
export const CABLES = '/cables';
export const CABLE = '/cables/:id';
export const CABLE_MANUFACTURERS = '/cable-manufacturers';
export const USER = '/user';
