import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {PROJECT, PROJECTS, PROJECT_PDF} from 'const/backend';
import useConfirmDialogContext from 'contexts/ConfirmDialogContext/useConfirmDialogContext';
import useApiMutation from 'hooks/useApiMutation';
import {Project} from 'interfaces/Project';
import generatePathWithQueryParams from 'utils/generatePathWithQueryParams';
import {UseSaveProject} from 'views/Calculator/hooks/useConfigurator/useSaveProject';
import ProjectHeaderView from './ProjectHeaderView';

type Props = {
  project: Project;
  saveProject: UseSaveProject;
  onProjectAction: () => void;
};

export default function ProjectHeader({
  project,
  saveProject,
  onProjectAction
}: Props): ReactElement {
  const {t} = useTranslation(['common']);
  const {mutate: cloneProject, apiMutationStatus: cloneProjectRequestStatus} =
    useApiMutation<Project>();
  const {mutate: deleteProject, apiMutationStatus: deleteProjectRequestStatus} =
    useApiMutation<Project>();
  const {mutate: requestPdf, apiMutationStatus: requestPdfStatus} = useApiMutation<Blob>();
  const openConfirmDialog = useConfirmDialogContext();

  function handleClone() {
    openConfirmDialog({
      onConfirm: () =>
        saveProject.send(project, () =>
          cloneProject(
            {
              path: PROJECTS,
              method: 'POST',
              body: {
                sourceId: project.id
              }
            },
            {
              onSuccess: function () {
                onProjectAction();
              }
            }
          )
        ),
      title: t('common:unsavedChanges.title'),
      text: t('common:unsavedChanges.text')
    });
  }

  function handleDelete() {
    openConfirmDialog({
      onConfirm: () =>
        deleteProject(
          {
            path: generatePathWithQueryParams(PROJECT, {id: `${project.id}`}),
            method: 'DELETE'
          },
          {
            onSuccess: function () {
              onProjectAction();
            }
          }
        ),
      title: t('common:deleteProject.dialog.title'),
      text: t('common:deleteProject.dialog.text')
    });
  }

  function handleGeneratePdf() {
    saveProject.send(project, function () {
      requestPdf(
        {
          method: 'GET',
          path: generatePathWithQueryParams(PROJECT_PDF, {id: `${project.id}`}),
          responseIsBlob: true
        },
        {
          onSuccess: function (response) {
            const fileUrl = URL.createObjectURL(response.data);
            window.open(fileUrl);
          }
        }
      );
    });
  }

  function handleSave() {
    saveProject.send(project);
  }

  return (
    <ProjectHeaderView
      project={project}
      t={t}
      onGeneratePdf={handleGeneratePdf}
      requestPdfStatus={requestPdfStatus}
      onClone={handleClone}
      cloneProjectRequestStatus={cloneProjectRequestStatus}
      onDelete={handleDelete}
      deleteProjectRequestStatus={deleteProjectRequestStatus}
      saveProjectRequestStatus={saveProject.requestStatus}
      onSave={handleSave}
    />
  );
}
