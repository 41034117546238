import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import {initReactI18next} from 'react-i18next';
import {ENABLED_LOCALES, Locale} from 'const/locales';
import userLocaleLocalStorage from 'utils/userLocaleLocalStorage';

const userLang = navigator.language || 'es';
const defaultLocale = userLang.substring(0, 2);

const enabledLocales: string[] = ENABLED_LOCALES;
export const locale = enabledLocales.includes(defaultLocale) ? defaultLocale : Locale.Spanish;

i18n
  .use(initReactI18next)
  .use(Backend)
  .init({
    lng: userLocaleLocalStorage.get() || locale,
    interpolation: {
      escapeValue: false
    },
    fallbackLng: false,
    ns: ['common', 'menu']
  });

export default i18n;
