function createLocalStorage() {
  return {
    get: (key: string) => window.localStorage.getItem(key),
    set: (key: string, value: string) => window.localStorage.setItem(key, value),
    delete: (key: string) => window.localStorage.removeItem(key)
  };
}

const localStorage = createLocalStorage();

export default localStorage;
