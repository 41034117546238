import {FINISHES} from 'const/backend';
import {ONE_HOUR_IN_MS} from 'const/time';
import useApiQuery, {UseApiQuery} from 'hooks/useApiQuery';
import {Finish} from 'interfaces/Finish';
import generatePathWithQueryParams from 'utils/generatePathWithQueryParams';

export default function useFinishes(traySystemId: number): UseApiQuery<Finish[]> {
  const path = generatePathWithQueryParams(
    FINISHES,
    {},
    {
      traySystemId
    }
  );

  return useApiQuery({
    requestOptions: {
      method: 'GET',
      path
    },
    queryOptions: {
      queryKey: [path],
      staleTime: ONE_HOUR_IN_MS
    }
  });
}
