import {CABLES} from 'const/backend';
import {ONE_HOUR_IN_MS} from 'const/time';
import useApiQuery, {UseApiQuery} from 'hooks/useApiQuery';
import {Cable, CableManufacturer} from 'interfaces/Project';
import generatePathWithQueryParams from 'utils/generatePathWithQueryParams';

export default function useGetCables(manufacturer: CableManufacturer): UseApiQuery<Cable[]> {
  const path = generatePathWithQueryParams(
    CABLES,
    {},
    {
      manufacturerId: manufacturer.name
    }
  );
  return useApiQuery({
    requestOptions: {
      method: 'GET',
      path
    },
    queryOptions: {
      queryKey: [CABLES, path],
      staleTime: ONE_HOUR_IN_MS
    }
  });
}
