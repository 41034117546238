import {ReactElement, ReactNode, useState} from 'react';
import {Box, useMediaQuery, useTheme} from '@mui/material';
import TextElement from './TextElement';

type Props = {
  text: string;
  href?: string;
  children?: ReactNode;
};

export default function ListItem({text, href, children = null}: Props): ReactElement {
  const [isOpened, setIsOpened] = useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  function toggleIsOpened() {
    setIsOpened((i) => !i);
  }

  function handleMouseEnter() {
    if (matches) {
      setIsOpened(true);
    }
  }

  function handleMouseLeave() {
    if (matches) {
      setIsOpened(false);
    }
  }

  return (
    <Box
      component="li"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        mb: {
          xs: 2,
          md: 0
        },
        mr: {
          xs: 0,
          md: 2
        }
      }}
    >
      <TextElement href={href} onClick={() => toggleIsOpened()} text={text}></TextElement>
      {isOpened && children}
    </Box>
  );
}
