import {createTheme} from '@mui/material';
import './fonts';

declare module '@mui/material/styles/createPalette' {
  interface PaletteOptions {
    borderColor: string;
  }

  interface Palette {
    borderColor: string;
  }
}

const theme = createTheme({
  typography: {
    fontFamily: "'Open Sans', sans-serif",
    h1: {
      fontSize: '2.25rem'
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 400
    },
    h3: {
      fontSize: '1.75rem'
    },
    h4: {
      fontSize: '1.5rem'
    },
    h5: {
      fontSize: '1.25rem'
    },
    h6: {
      fontSize: '1.25rem'
    }
  },
  palette: {
    primary: {
      main: '#e30613'
    },
    secondary: {
      main: '#545661'
    },
    grey: {
      50: '#fafafa',
      100: '#f0f0f0',
      200: '#dedede',
      300: '#cccccc',
      400: '#ced4da',
      500: '#9d9d9c'
    },
    borderColor: 'rgba(0,0,0,0.23)'
  }
});

export default theme;
