export const ONE_SECOND = 1000;
export const ONE_HOUR_IN_SECONDS = 60 * 60;
export const ONE_DAY_IN_SECONDS = 24 * ONE_HOUR_IN_SECONDS;
export const SIX_DAYS_IN_SECONDS = 6 * ONE_DAY_IN_SECONDS;
export const FIFTEEN_DAYS_IN_SECONDS = 15 * ONE_DAY_IN_SECONDS;
export const ONE_MONTH_IN_SECONDS = 30 * ONE_DAY_IN_SECONDS;
export const TWO_MONTHS_IN_SECONDS = 2 * ONE_MONTH_IN_SECONDS;
export const ONE_HOUR_IN_MS = ONE_HOUR_IN_SECONDS * 1000;
export const FIVE_MINUTES = 5 * 60 * ONE_SECOND;
export const TWO_DAYS_IN_MS = 2 * ONE_DAY_IN_SECONDS * 1000;
