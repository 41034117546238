import {ReactElement} from 'react';
import SearchIcon from '@mui/icons-material/Search';
import {Box, Container, IconButton, Paper, styled} from '@mui/material';
import {useTranslation} from 'react-i18next';

const Input = styled('input')(({theme}) => ({
  display: 'block',
  minWidth: '90%',
  paddingTop: '5px',
  paddingBottom: '5px',
  backgroundColor: theme.palette.common.white,
  borderRadius: theme.shape.borderRadius,
  border: 'none',
  WebkitAppearance: 'none',
  outline: 0
}));

export default function TopBar(): ReactElement {
  const {t} = useTranslation(['common']);
  return (
    <>
      <div className="letterhead">
        <div className="letterhead__left"></div>
        <div className="letterhead__right"></div>
      </div>
      <Box
        sx={{
          mb: 1,
          py: 0.5,
          bgcolor: 'grey.300'
        }}
      >
        <Container>
          <Box
            sx={{
              width: {
                xs: '100%',
                md: '60%'
              },
              mx: 'auto',
              py: 1
            }}
          >
            <Paper
              component="form"
              sx={{
                p: '0px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%'
              }}
              elevation={0}
              action="https://www.pemsa-rejiband.com/"
              name="search-form"
              role="search"
              method="get"
              target="_blank"
            >
              <Input aria-label={t('common:search')} name="s" />
              <IconButton type="submit" sx={{p: '2px 10px'}} aria-label="search">
                <SearchIcon />
              </IconButton>
            </Paper>
          </Box>
        </Container>
      </Box>
    </>
  );
}
