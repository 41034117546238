import {memo, ReactElement, Suspense} from 'react';
import ErrorIndicator from 'components/shared/ui/ErrorIndicator';
import {ApiQueryStatus} from 'interfaces/ApiRequestStatus';
import {LoadingBox, LoadingIcon, LoadingText} from '../ui/LoadingIndicator';

type Props = {
  apiQueryStatus: ApiQueryStatus[];
  children: () => ReactElement | null;
  loadingElement?: ReactElement;
  loadingText?: string;
  loadingIndicatorHidden?: boolean;
};

function ApiSuspense({
  apiQueryStatus,
  children,
  loadingElement,
  loadingText,
  loadingIndicatorHidden = false
}: Props): ReactElement | null {
  const loader: null | ReactElement = loadingIndicatorHidden ? null : loadingElement ? (
    loadingElement
  ) : (
    <LoadingBox centered>
      <LoadingIcon />
      <LoadingText text={loadingText} />
    </LoadingBox>
  );

  if (apiQueryStatus.filter((status) => status.dataStatus.isLoading).length > 0) {
    return loader;
  }

  const failedRequests = apiQueryStatus.filter((status) => status.dataStatus.isError);
  if (failedRequests.length > 0) {
    return <ErrorIndicator />;
  }

  return (
    <Suspense
      fallback={
        <LoadingBox>
          <LoadingIcon />
        </LoadingBox>
      }
    >
      {children()}
    </Suspense>
  );
}

export default memo(ApiSuspense);
