import {ReactElement, useEffect, useState} from 'react';
import {SelectChangeEvent} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {useQueryClient} from 'react-query';
import {PROJECTS} from 'const/backend';
import useConfirmDialogContext from 'contexts/ConfirmDialogContext/useConfirmDialogContext';
import useApiMutation from 'hooks/useApiMutation';
import {Project} from 'interfaces/Project';
import {System} from 'interfaces/System';
import generatePathWithQueryParams from 'utils/generatePathWithQueryParams';
import CalculatorView from './CalculatorView';
import useConfigurator from './hooks/useConfigurator';
import useGetProject from './hooks/useGetProject';

type Props = {
  projects: Project[];
  systems: System[];
};

export default function Calculator({projects, systems}: Props): ReactElement {
  const {t} = useTranslation('common');
  const [selectProjectForm, setSelectProjectForm] = useState({
    value: projects.length > 0 ? `${projects[0].id}` : null,
    options: projects
  });
  const {mutate: createProject, apiMutationStatus: createProjectRequestStatus} =
    useApiMutation<Project>();
  const queryClient = useQueryClient();

  useEffect(
    function () {
      const firstProject = projects.length > 0 ? `${projects[0].id}` : null;
      setSelectProjectForm((currentSelectProjectForm) => ({
        value:
          currentSelectProjectForm.value === null ? firstProject : currentSelectProjectForm.value,
        options: projects
      }));
    },
    [projects]
  );

  function selectFirstProject() {
    const projectsPath = generatePathWithQueryParams(PROJECTS, {});
    queryClient.invalidateQueries([projectsPath]);
    setSelectProjectForm((currentSelectProjectForm) => ({
      ...currentSelectProjectForm,
      value: null
    }));
  }

  const {apiQueryStatus: getProjectStatus, apiResponse: getProjectResponse} = useGetProject(
    selectProjectForm.value ? parseInt(selectProjectForm.value) : null
  );

  const [editableProject, configuratorActions, saveProject] = useConfigurator({
    project: getProjectResponse?.data ?? null,
    defaultSystemReference:
      systems.find((s) => s.reference === 'Rejiband Rapide')?.id ?? systems[0].id
  });

  const openConfirmDialog = useConfirmDialogContext();

  function handleCreate() {
    function createProjectRequest() {
      createProject(
        {
          path: PROJECTS,
          method: 'POST'
        },
        {
          onSuccess: function () {
            selectFirstProject();
          }
        }
      );
    }
    if (editableProject) {
      openConfirmDialog({
        onConfirm: () => saveProject.send(editableProject, createProjectRequest),
        title: t('common:unsavedChanges.title'),
        text: t('common:unsavedChanges.text')
      });
    } else {
      createProjectRequest();
    }
  }

  function handleSelectProject(event: SelectChangeEvent) {
    function selectProject() {
      setSelectProjectForm((currentSelectProjectForm) => ({
        ...currentSelectProjectForm,
        value: event.target.value
      }));
    }
    if (editableProject) {
      openConfirmDialog({
        onConfirm: () =>
          saveProject.send(editableProject, function () {
            selectProject();
          }),
        title: t('common:unsavedChanges.title'),
        text: t('common:unsavedChanges.text')
      });
    } else {
      selectProject();
    }
  }

  return (
    <CalculatorView
      editableProject={editableProject}
      selectProjectForm={selectProjectForm}
      systems={systems}
      configuratorActions={configuratorActions}
      getProjectStatus={getProjectStatus}
      saveProject={saveProject}
      onSelectProject={handleSelectProject}
      onCreate={handleCreate}
      createProjectRequestStatus={createProjectRequestStatus}
      selectFirstProject={selectFirstProject}
    />
  );
}
