import {ReactElement} from 'react';
import DescriptionIcon from '@mui/icons-material/Description';
import DevicesIcon from '@mui/icons-material/Devices';
import GridOnIcon from '@mui/icons-material/GridOn';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import PostAddIcon from '@mui/icons-material/PostAdd';
import TheatersIcon from '@mui/icons-material/Theaters';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import {Box, Grid, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import TrayInformation from 'components/shared/tray/TrayInformation';
import {Section, Tray as TrayInterface} from 'interfaces/Project';
import getTrayName from 'utils/getTrayName';
import InfoBox from './components/InfoBox';
import TitleBox from './components/TitleBox';
import TrayImage from './components/TrayImage';

type Props = {
  tray: TrayInterface;
  section: Section;
};

const iconSx = {
  color: 'primary.main'
};

export default function Tray({tray, section}: Props): ReactElement {
  const {t} = useTranslation('common');
  const totalSection = section.totalSection === 0 ? tray.totalSection : section.totalSection;
  return (
    <Box
      sx={{
        p: {
          xs: 2,
          lg: 4
        },
        bgcolor: 'grey.50',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: 'borderColor',
        borderRadius: 1
      }}
    >
      <Grid
        container
        spacing={2}
        mb={4}
        sx={{
          flexDirection: {
            lg: 'row-reverse'
          }
        }}
      >
        <Grid item xs={12} lg={4}>
          <TrayImage src={tray.image} alt={getTrayName(section)} />
        </Grid>
        <Grid item xs={12} lg={8}>
          <Typography variant="h6">{t('common:selectedProduct')}</Typography>
          <Typography fontWeight="bold" paragraph>
            {getTrayName(section)}
          </Typography>
          <TrayInformation t={t} tray={tray} totalSection={totalSection} />
        </Grid>
      </Grid>

      <Typography fontWeight="bold" paragraph>
        {t('common:relatedInformation')}
      </Typography>
      <Grid container spacing={2} sx={{mb: 4}}>
        {tray.url && (
          <Grid item xs={12} lg={6}>
            <TitleBox
              text={t('common:onlineCatalog')}
              icon={<DevicesIcon sx={iconSx} color="primary" />}
            />
            <InfoBox t={t} value={tray.url} />
          </Grid>
        )}
        <Grid item xs={12} lg={6}>
          <TitleBox text={t('common:bim')} icon={<ViewInArIcon sx={iconSx} />} />
          <InfoBox t={t} value={tray.rvt} />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{mb: 4}}>
        <Grid item xs={12} lg={4}>
          <TitleBox text={t('common:workItem')} icon={<PostAddIcon sx={iconSx} />} />
          <InfoBox t={t} value={tray.acae} />
        </Grid>
        <Grid item xs={12} lg={4}>
          <TitleBox text={t('common:workItemBc3')} icon={<DescriptionIcon sx={iconSx} />} />
          <InfoBox t={t} value={tray.bc3} />
        </Grid>
        <Grid item xs={12} lg={4}>
          <TitleBox text={t('common:workItemXls')} icon={<GridOnIcon sx={iconSx} />} />
          <InfoBox t={t} value={tray.xls} />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <TitleBox text={t('common:catalogs')} icon={<ImportContactsIcon sx={iconSx} />} />
          {tray.catalogs.map((catalog) => (
            <InfoBox t={t} label={catalog.name} value={catalog.link} key={catalog.id} />
          ))}
        </Grid>
        <Grid item xs={12} lg={6}>
          <TitleBox text={t('common:videos')} icon={<TheatersIcon sx={iconSx} />} />
          {tray.videos.map((video) => (
            <InfoBox
              t={t}
              label={video.name === '' ? video.reference : video.name}
              value={video.link}
              key={video.id}
            />
          ))}
        </Grid>
      </Grid>
    </Box>
  );
}
