import {ReactElement, useMemo} from 'react';
import AddIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import {Box, IconButton, Tooltip} from '@mui/material';
import {DataGrid, GridColDef, GridRowParams, esES, enUS, ptBR, frFR} from '@mui/x-data-grid';
import {TFunction, useTranslation} from 'react-i18next';
import {Locale} from 'const/locales';
import {Cable} from 'interfaces/Project';
import formatNumber from 'utils/formatNumber';
type Props = {
  cables: Cable[];
  onCableClick: (cable: Cable) => void;
  onCableEdit: (cable: Cable) => void;
  t: TFunction;
};

const dataGridSx = {
  '& .MuiDataGrid-columnHeaderTitle': {
    fontWeight: 700,
    color: 'rgba(0, 0, 0, 0.87)'
  }
};

export default function CablesTableView({
  cables,
  onCableClick,
  onCableEdit,
  t
}: Props): ReactElement {
  const {i18n} = useTranslation();
  let locale = esES;
  if (i18n.language === Locale.English) {
    locale = enUS;
  } else if (i18n.language === Locale.Portuguese) {
    locale = ptBR;
  } else if (i18n.language === Locale.French) {
    locale = frFR;
  }

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'name',
        headerName: t('common:designation'),
        flex: 1,
        sortable: false,
        hideable: false,
        minWidth: 150,
        disableColumnMenu: true
      },
      {
        field: 'brand',
        headerName: t('common:commercialBrand'),
        flex: 1,
        sortable: false,
        hideable: false,
        minWidth: 150,
        disableColumnMenu: true
      },
      {
        field: 'conductingSection',
        headerName: t('common:conductingSection'),
        flex: 1,
        sortable: false,
        hideable: false,
        minWidth: 150,
        disableColumnMenu: true
      },
      {
        field: 'diameter',
        headerName: t('common:externalDiameter'),
        flex: 1,
        sortable: false,
        hideable: false,
        minWidth: 150,
        disableColumnMenu: true,
        valueFormatter: ({value}) => formatNumber(value)
      },
      {
        field: 'weight',
        headerName: t('common:weightKgByMeter'),
        flex: 1,
        sortable: false,
        hideable: false,
        minWidth: 150,
        disableColumnMenu: true,
        valueFormatter: ({value}) => formatNumber(value)
      },
      {
        field: 'action',
        headerName: '',
        sortable: false,
        minWidth: 150,
        renderCell: ({row: cable}: Partial<GridRowParams>) => (
          <>
            <Tooltip title={t('common:addCable')}>
              <IconButton onClick={() => onCableClick(cable)}>
                <AddIcon color="primary" />
              </IconButton>
            </Tooltip>
            {cable.isEditable ? (
              <IconButton
                onClick={(event) => {
                  event.stopPropagation();
                  onCableEdit(cable);
                }}
              >
                <EditIcon />
              </IconButton>
            ) : null}
          </>
        )
      }
    ],
    [t, onCableClick, onCableEdit]
  );

  return (
    <Box sx={{width: '100%', height: 400}}>
      <Box sx={{display: 'flex', height: '100%'}}>
        <Box sx={{flexGrow: 1}}>
          <DataGrid
            sx={dataGridSx}
            disableColumnFilter
            rows={cables}
            columns={columns}
            disableSelectionOnClick
            disableColumnSelector
            disableDensitySelector
            localeText={locale.components.MuiDataGrid.defaultProps.localeText}
          />
        </Box>
      </Box>
    </Box>
  );
}
