import {ChangeEvent, ReactElement, useEffect, useRef, useState} from 'react';
import {
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Box
} from '@mui/material';
import {useTranslation} from 'react-i18next';
import FieldBox from 'components/shared/ui/FieldBox';
import useConfirmDialogContext from 'contexts/ConfirmDialogContext/useConfirmDialogContext';
import {CablesPlacement as ICablesPlacement} from 'interfaces/Project';

type Props = {
  placement: ICablesPlacement;
  hasSelectedTray: boolean;
  deleteTrayFromSection: () => void;
  onChange: (placement: ICablesPlacement) => void;
};

enum PlacementOption {
  WithLayers = 'conCapa',
  WithoutLayers = 'sinCapa'
}

enum PositionOption {
  WithSeparation = 'conSep',
  WithoutSeparation = 'sinSep'
}

export default function CablesPlacement({
  placement,
  hasSelectedTray,
  deleteTrayFromSection,
  onChange
}: Props): ReactElement {
  const {t} = useTranslation('common');
  const [selectedPlacement, setSelectedPlacement] = useState(
    placement === ICablesPlacement.WithLayers
      ? PlacementOption.WithLayers
      : PlacementOption.WithoutLayers
  );
  const [selectedPosition, setSelectedPosition] = useState(
    placement === ICablesPlacement.WithLayers ? '' : placement
  );
  const shouldShowConfirmDialog = useRef(hasSelectedTray);
  const openConfirmDialog = useConfirmDialogContext();

  function changePlacement(placementOption: PlacementOption) {
    setSelectedPlacement(placementOption);
    if (placementOption === PlacementOption.WithLayers) {
      setSelectedPosition('');
      onChange(ICablesPlacement.WithLayers);
    }
  }

  useEffect(
    function () {
      shouldShowConfirmDialog.current = hasSelectedTray;
    },
    [hasSelectedTray]
  );

  function handlePlacementChanged(event: ChangeEvent<HTMLInputElement>) {
    if (shouldShowConfirmDialog.current) {
      event.preventDefault();
      openConfirmDialog({
        onConfirm: function () {
          shouldShowConfirmDialog.current = false;
          deleteTrayFromSection();
          changePlacement(event.target.value as PlacementOption);
        },
        title: t('common:confirmResetTray.title'),
        text: t('common:confirmResetTray.text')
      });
    } else {
      changePlacement(event.target.value as PlacementOption);
    }
  }

  function handlePositionChanged(event: SelectChangeEvent) {
    if (shouldShowConfirmDialog.current) {
      event.preventDefault();
      openConfirmDialog({
        onConfirm: function () {
          shouldShowConfirmDialog.current = false;
          deleteTrayFromSection();
          setSelectedPosition(event.target.value as string);
          onChange(event.target.value as ICablesPlacement);
        },
        title: t('common:confirmResetTray.title'),
        text: t('common:confirmResetTray.text')
      });
    } else {
      setSelectedPosition(event.target.value as string);
      onChange(event.target.value as ICablesPlacement);
    }
  }

  return (
    <FieldBox label={t('common:cablesPlacement')}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexWrap: {
            xs: 'wrap',
            lg: 'nowrap'
          }
        }}
      >
        <Box
          sx={{
            flex: {
              xs: '0 0 100%',
              lg: '0 1 50%'
            }
          }}
        >
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="cable-placement-label"
              value={selectedPlacement}
              name="radio-buttons-group"
              onChange={handlePlacementChanged}
            >
              <FormControlLabel
                value={PlacementOption.WithLayers}
                control={<Radio />}
                label={t('common:cablesWithLayers')}
                sx={{
                  mb: {
                    xs: 2,
                    sm: 0
                  }
                }}
              />
              <FormControlLabel
                value={PlacementOption.WithoutLayers}
                control={<Radio />}
                label={t('common:cablesWithoutLayers')}
              />
            </RadioGroup>
          </FormControl>
        </Box>
        <Box
          sx={{
            flex: {
              xs: '0 0 100%',
              lg: '0 1 50%'
            }
          }}
        >
          <FormControl fullWidth sx={{mt: 1}}>
            <InputLabel id="position-select-label">{t('common:cablesPosition')}</InputLabel>
            <Select
              labelId="position-select-label"
              id="position-select"
              value={selectedPosition}
              label={t('common:cablesPosition')}
              onChange={handlePositionChanged}
              disabled={selectedPlacement === PlacementOption.WithLayers}
            >
              <MenuItem value={PositionOption.WithoutSeparation}>
                {t('common:cablesWithoutSeparation')}
              </MenuItem>
              <MenuItem value={PositionOption.WithSeparation}>
                {t('common:cablesWithSeparation')}
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
    </FieldBox>
  );
}
