import {ReactElement, ReactNode} from 'react';
import {Box, Typography} from '@mui/material';

type Props = {
  icon: ReactNode;
  text: string;
};

export default function TitleBox({icon, text}: Props): ReactElement {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        mb: 1
      }}
    >
      {icon}
      <Typography ml={0.5} fontWeight="700">
        {text}
      </Typography>
    </Box>
  );
}
