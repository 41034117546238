import {ReactElement} from 'react';
import {TFunction} from 'react-i18next';
import ErrorIndicator from 'components/shared/ui/ErrorIndicator';
import {Cable, CableManufacturer} from 'interfaces/Project';
import CablesTableView from './CablesTableView';
import useGetCables from './hooks/useGetCables';

type Props = {
  manufacturer: CableManufacturer;
  onCableClick: (cable: Cable) => void;
  onCableEdit: (cable: Cable) => void;
  t: TFunction;
};

export default function CablesTable({
  manufacturer,
  onCableClick,
  onCableEdit,
  t
}: Props): ReactElement {
  const {apiQueryStatus: getCablesRequestStatus, apiResponse: getCablesResponse} =
    useGetCables(manufacturer);

  if (getCablesRequestStatus.dataStatus.isError || !getCablesResponse) {
    return <ErrorIndicator />;
  }

  return (
    <CablesTableView
      cables={getCablesResponse.data}
      onCableClick={onCableClick}
      onCableEdit={onCableEdit}
      t={t}
    />
  );
}
