import {ReactElement, useState} from 'react';
import {PROJECT_PDF} from 'const/backend';
import useApiMutation from 'hooks/useApiMutation';
import {Project} from 'interfaces/Project';
import {System} from 'interfaces/System';
import generatePathWithQueryParams from 'utils/generatePathWithQueryParams';
import {UseConfiguratorActions} from 'views/Calculator/hooks/useConfigurator';
import {UseSaveProject} from 'views/Calculator/hooks/useConfigurator/useSaveProject';
import ProjectConfiguratorView from './ProjectConfiguratorView';
import downloadBlob from './utils/downloadBlob';

type Props = {
  editableProject: Project;
  systems: System[];
  configuratorActions: UseConfiguratorActions;
  onProjectAction: () => void;
  saveProject: UseSaveProject;
};

export default function ProjectConfigurator({
  configuratorActions,
  editableProject,
  systems,
  onProjectAction,
  saveProject
}: Props): ReactElement {
  const {mutate: requestPdf} = useApiMutation<Blob>();
  const [isGeneratingPdf, setIsGeneratingPdf] = useState(false);

  function handleGeneratePdf() {
    setIsGeneratingPdf(true);
    saveProject.send(
      editableProject,
      function () {
        requestPdf(
          {
            method: 'GET',
            path: generatePathWithQueryParams(PROJECT_PDF, {id: `${editableProject.id}`}),
            responseIsBlob: true
          },
          {
            onSuccess: function (response) {
              downloadBlob(response.data, editableProject);
              setIsGeneratingPdf(false);
            },
            onError: () => setIsGeneratingPdf(false)
          }
        );
      },
      () => setIsGeneratingPdf(false)
    );
  }

  return (
    <ProjectConfiguratorView
      configuratorActions={configuratorActions}
      isGeneratingPdf={isGeneratingPdf}
      onGeneratePdf={handleGeneratePdf}
      onProjectAction={onProjectAction}
      project={editableProject}
      systems={systems}
      saveProject={saveProject}
    />
  );
}
