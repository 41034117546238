import {ReactElement} from 'react';
import {Box, List, ListItem, ListItemText, Typography} from '@mui/material';
import {TFunction} from 'react-i18next';
import {Tray} from 'interfaces/Project';

type Props = {
  tray: Tray;
  totalSection?: number | null;
  t: TFunction;
};

const primaryTypographyProps = {
  sx: {
    fontWeight: 'bold',
    fontSize: '0.975rem'
  }
};

export default function TrayInformation({tray, totalSection = null, t}: Props): ReactElement {
  const totalSectionToShow = totalSection ?? tray.totalSection;

  return (
    <Box>
      <Typography variant="h6">{t('common:information')}</Typography>
      <List dense>
        {tray.description && (
          <ListItem disableGutters>
            <ListItemText
              primaryTypographyProps={primaryTypographyProps}
              primary={t('common:description')}
              secondary={tray.description}
            />
          </ListItem>
        )}
        <ListItem disableGutters>
          <ListItemText
            primary={t('common:usefulSection')}
            primaryTypographyProps={primaryTypographyProps}
            secondary={new Intl.NumberFormat(undefined, {
              style: 'unit',
              unit: 'millimeter',
              useGrouping: false
            }).format(tray.usefulSection)}
          />
        </ListItem>
        {totalSectionToShow && (
          <ListItemText
            primary={t('common:totalSection')}
            primaryTypographyProps={primaryTypographyProps}
            secondary={new Intl.NumberFormat(undefined, {
              style: 'unit',
              unit: 'millimeter',
              useGrouping: false,
              maximumFractionDigits: 2
            }).format(totalSectionToShow)}
          />
        )}
        {tray.cablesWeight && (
          <ListItem disableGutters>
            <ListItemText
              primary={t('common:cablesWeight')}
              primaryTypographyProps={primaryTypographyProps}
              secondary={new Intl.NumberFormat(undefined, {
                style: 'unit',
                unit: 'kilogram',
                useGrouping: false,
                maximumFractionDigits: 2
              }).format(tray.cablesWeight)}
            />
          </ListItem>
        )}
        {tray.maxLoad && (
          <ListItem disableGutters>
            <ListItemText
              primary={t('common:maxLoad')}
              primaryTypographyProps={primaryTypographyProps}
              secondary={new Intl.NumberFormat(undefined, {
                style: 'unit',
                unit: 'kilogram-per-meter',
                useGrouping: false
              }).format(tray.maxLoad)}
            />
          </ListItem>
        )}
      </List>
    </Box>
  );
}
