import {PROJECT} from 'const/backend';
import {ONE_HOUR_IN_MS} from 'const/time';
import useApiQuery, {UseApiQuery} from 'hooks/useApiQuery';
import {Project, ProjectId} from 'interfaces/Project';
import generatePathWithQueryParams from 'utils/generatePathWithQueryParams';

type UseProject = UseApiQuery<Project>;

export default function useGetProject(projectId: ProjectId | null): UseProject {
  const path = generatePathWithQueryParams(PROJECT, {id: `${projectId}`});

  return useApiQuery({
    requestOptions: {
      method: 'GET',
      path: path
    },
    queryOptions: {
      queryKey: [path],
      staleTime: ONE_HOUR_IN_MS,
      enabled: projectId !== null
    }
  });
}
