import {ReactElement} from 'react';
import {Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent} from '@mui/material';
import {TFunction} from 'react-i18next';
import {Project} from 'interfaces/Project';

type Props = {
  t: TFunction;
  onSelectProject: (event: SelectChangeEvent) => void;
  selectProjectForm: {
    value: string | null;
    options: Project[];
  };
};

export default function SelectProject({
  t,
  onSelectProject,
  selectProjectForm
}: Props): ReactElement {
  return (
    <Box>
      <FormControl fullWidth>
        <InputLabel id="select-project–label">{t('common:project')}</InputLabel>
        <Select
          labelId="select-project–label"
          id="select-project"
          label={t('common:project')}
          value={selectProjectForm.value ?? ''}
          onChange={onSelectProject}
        >
          {selectProjectForm.options.map((project) => (
            <MenuItem value={`${project.id}`} key={`project--${project.id}`}>
              {project.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
