import {useCallback} from 'react';
import {useQueryClient} from 'react-query';
import {PROJECT, PROJECTS} from 'const/backend';
import useApiMutation from 'hooks/useApiMutation';
import {ApiMutationStatus} from 'interfaces/ApiRequestStatus';
import ApiResponse from 'interfaces/ApiResponse';
import {Project} from 'interfaces/Project';
import generatePathWithQueryParams from 'utils/generatePathWithQueryParams';

export type SendSaveProject = (
  project: Project,
  onSuccessCallback?: () => void,
  onErrorCallback?: () => void
) => void;

export type UseSaveProject = {
  send: SendSaveProject;
  requestStatus: ApiMutationStatus;
};

export default function useSaveProject(): UseSaveProject {
  const {mutate: putProjectMutation, apiMutationStatus: putProjectRequestStatus} =
    useApiMutation<Project>();
  const queryClient = useQueryClient();

  const saveProject = useCallback(
    function (project: Project, onSuccessCallback?: () => void, onErrorCallback?: () => void) {
      const path = generatePathWithQueryParams(PROJECT, {id: `${project.id}`});
      putProjectMutation(
        {
          method: 'PUT',
          path,
          body: project
        },
        {
          onSuccess: function (response) {
            const projectsPath = generatePathWithQueryParams(PROJECTS, {});
            queryClient.setQueryData([path], response);
            queryClient.setQueryData<ApiResponse<Project[]> | undefined>(
              [projectsPath],
              function (oldProjectsResponse) {
                if (!oldProjectsResponse) {
                  return oldProjectsResponse;
                }
                return {
                  ...oldProjectsResponse,
                  data: oldProjectsResponse.data.map((project) =>
                    project.id === response.data.id ? response.data : project
                  )
                };
              }
            );
            onSuccessCallback?.();
          },
          onError: function () {
            onErrorCallback?.();
          }
        }
      );
    },
    [putProjectMutation, queryClient]
  );

  return {
    send: saveProject,
    requestStatus: putProjectRequestStatus
  };
}
