import {MouseEventHandler, ReactElement} from 'react';
import AddIcon from '@mui/icons-material/Add';
import {Button} from '@mui/material';
import {TFunction} from 'react-i18next';

type Props = {
  onClick: MouseEventHandler<HTMLButtonElement>;
  t: TFunction;
};

export default function CreateCable({onClick, t}: Props): ReactElement {
  return (
    <Button
      aria-label="create-cable"
      onClick={onClick}
      startIcon={<AddIcon />}
      variant="outlined"
      color="secondary"
      fullWidth
      sx={{
        height: '100%'
      }}
    >
      {t('common:createCable')}
    </Button>
  );
}
