import {ChangeEvent, FormEvent, MouseEvent, ReactElement} from 'react';
import {Alert, Box, Button, Grid, TextField} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {ApiMutationStatus} from 'interfaces/ApiRequestStatus';
import {Form, Errors} from './types';

type Props = {
  form: Form;
  errors: Errors;
  onSubmit: (event: FormEvent<HTMLFormElement>) => void;
  onGoBack: (event: MouseEvent<HTMLButtonElement>) => void;
  onInputChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onNumberInputChange: (event: ChangeEvent<HTMLInputElement>) => void;
  createCableRequestStatus: ApiMutationStatus;
  isEditing: boolean;
};

export default function CableFormView({
  errors,
  form,
  onGoBack,
  onSubmit,
  onInputChange,
  onNumberInputChange,
  createCableRequestStatus,
  isEditing
}: Props): ReactElement {
  const {t} = useTranslation('common');
  return (
    <Box>
      <form onSubmit={onSubmit}>
        <Grid container spacing={2} sx={{mb: 2}}>
          <Grid item xs={12} lg={6}>
            <TextField
              id="manufacturer"
              label={t('common:manufacturer')}
              name="manufacturer"
              value={form.manufacturer}
              onChange={onInputChange}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextField
              id="name"
              label={t('common:name')}
              name="name"
              value={form.name}
              onChange={onInputChange}
              fullWidth
              required
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{mb: 2}}>
          <Grid item xs={12} lg={6}>
            <TextField
              id="brand"
              label={t('common:commercialBrand')}
              name="brand"
              value={form.brand}
              onChange={onInputChange}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextField
              id="conductingSection"
              label={t('common:conductingSection')}
              name="conductingSection"
              value={form.conductingSection}
              onChange={onInputChange}
              fullWidth
              required
              disabled={isEditing}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{mb: 2}}>
          <Grid item xs={12} lg={6}>
            <TextField
              id="diameter"
              label={t('common:externalDiameter')}
              name="diameter"
              value={form.diameter}
              onChange={onNumberInputChange}
              fullWidth
              required
              type="text"
              disabled={isEditing}
              error={!!errors.diameter}
              helperText={errors.diameter ? t(errors.diameter) : null}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextField
              id="weight"
              label={t('common:weightKgByMeter')}
              name="weight"
              value={form.weight}
              onChange={onNumberInputChange}
              fullWidth
              required
              type="text"
              disabled={isEditing}
              error={!!errors.weight}
              helperText={errors.weight ? t(errors.weight) : null}
            />
          </Grid>
        </Grid>
        <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
          <Button
            type="submit"
            color="primary"
            variant="text"
            onClick={onGoBack}
            disabled={createCableRequestStatus.isLoading}
          >
            {t('common:goBack')}
          </Button>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            sx={{ml: 2}}
            disabled={createCableRequestStatus.isLoading}
          >
            {isEditing ? t('common:editCable') : t('common:createCable')}
          </Button>
        </Box>
        {createCableRequestStatus.isError && (
          <Alert severity="error" sx={{width: '100%', mt: 2}}>
            {t('common:createCableError')}
          </Alert>
        )}
      </form>
    </Box>
  );
}
