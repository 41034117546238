import {ReactElement} from 'react';
import {styled} from '@mui/material';

type Props = {
  mr?: number;
  icon: string;
};

type IconProps = {
  mr?: number;
};

const Icon = styled('i', {
  shouldForwardProp: (prop) => prop !== 'mr'
})<IconProps>(({theme, mr}) => ({
  marginRight: mr ? theme.spacing(mr) : undefined,
  fontFamily: 'PemsaIcon',
  fontStyle: 'normal'
}));

export default function PemsaIcon({mr, icon}: Props): ReactElement {
  return <Icon mr={mr}>{icon}</Icon>;
}
