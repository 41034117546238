import {ReactElement, ReactNode} from 'react';
import {Box, Typography} from '@mui/material';
type Props = {
  label: string;
  children: ReactNode;
};

export default function FieldBox({label, children}: Props): ReactElement {
  return (
    <Box
      sx={{
        position: 'relative',
        px: {
          xs: 2,
          lg: 4
        },
        pb: {
          xs: 2,
          lg: 4
        },
        pt: {
          xs: 6,
          lg: 4
        },
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: 'borderColor',
        borderRadius: 1
      }}
    >
      <Typography
        sx={{
          position: 'absolute',
          top: -20,
          left: 30,
          p: 1,
          bgcolor: 'common.white',
          color: 'text.secondary'
        }}
      >
        {label}
      </Typography>
      {children}
    </Box>
  );
}
