import {ReactElement, memo} from 'react';
import {Section as ISection} from 'interfaces/Project';
import {System} from 'interfaces/System';
import {UseConfiguratorActions} from 'views/Calculator/hooks/useConfigurator';
import SectionAccordion from './Section/SectionAccordion';

type Props = {
  sections: ISection[];
  configuratorActions: UseConfiguratorActions;
  systems: System[];
};

function ProjectSections({sections, configuratorActions, systems}: Props): ReactElement {
  return (
    <>
      {sections.map((section, index) => (
        <SectionAccordion
          section={section}
          key={section.id}
          index={index}
          otherSections={sections.filter((s) => section.id !== s.id)}
          configuratorActions={configuratorActions}
          systems={systems}
        />
      ))}
    </>
  );
}

export default memo(ProjectSections);
