import {ReactElement, ReactNode} from 'react';
import {Box} from '@mui/material';

type Props = {
  centered?: boolean;
  children: ReactNode;
};

const baseSx = {
  display: 'flex',
  alignItems: 'center'
};

const centeredSx = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%'
};

function LoadingBox({centered = false, children}: Props): ReactElement {
  return <Box sx={centered ? {...baseSx, ...centeredSx} : baseSx}>{children}</Box>;
}

export default LoadingBox;
