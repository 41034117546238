import {ChangeEvent, ReactElement, useState} from 'react';
import {InputAdornment, TextField} from '@mui/material';
import {TFunction} from 'react-i18next';

type Props = {
  initialValue: number | null;
  t: TFunction;
  onChange: (value: number) => void;
};

export default function SectionLength({t, initialValue, onChange}: Props): ReactElement {
  const [value, setValue] = useState(`${initialValue}`);

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    setValue(event.target.value);
    const number = parseFloat(event.target.value);
    if (!isNaN(number)) {
      onChange(Number(event.target.value));
    }
  }

  return (
    <TextField
      id="sectionLength"
      label={t('common:sectionLength')}
      value={value}
      InputProps={{
        endAdornment: <InputAdornment position="end">m</InputAdornment>
      }}
      onChange={handleChange}
      inputProps={{
        step: '0.01'
      }}
      type="number"
      fullWidth
    />
  );
}
