import {ReactElement} from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {Box, IconButton, Tooltip, Typography} from '@mui/material';
import {TFunction} from 'react-i18next';

type Props = {
  value: string | null;
  label?: string;
  t: TFunction;
};

export default function InfoBox({value, t, label}: Props): ReactElement {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: 56,
        py: 0.5,
        px: 2,
        borderRadius: 1,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: 'grey.100',
        bgcolor: value ? 'common.white' : 'grey.100'
      }}
    >
      <Typography noWrap>{label ?? value ?? t('common:notAvailable')}</Typography>
      {value && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Tooltip title={t('common:copy')}>
            <IconButton
              edge="end"
              aria-label={t('common:copy')}
              onClick={() => navigator.clipboard.writeText(value)}
              sx={{
                display: {
                  xs: 'none',
                  lg: 'inline-block'
                }
              }}
            >
              <ContentCopyIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={t('common:openInNewTab')}>
            <IconButton edge="end" aria-label={t('openInNewTab')} href={value} target="_blank">
              <OpenInNewIcon />
            </IconButton>
          </Tooltip>
        </Box>
      )}
    </Box>
  );
}
