import {ChangeEvent, ReactElement, useRef, useState} from 'react';
import {
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  Slider,
  TextField,
  Typography
} from '@mui/material';
import {TFunction, useTranslation} from 'react-i18next';
import FieldBox from 'components/shared/ui/FieldBox';
import useConfirmDialogContext from 'contexts/ConfirmDialogContext/useConfirmDialogContext';
import {ExtensionType} from 'interfaces/Project';

type Props = {
  deleteTrayFromSection: () => void;
  extensionType: ExtensionType;
  hasSelectedTray: boolean;
  initialExtensionValue: number | null;
  onValueChange: (value: number) => void;
  onExtensionTypeChange: (value: ExtensionType) => void;
  t: TFunction;
};

export default function SectionExtension({
  deleteTrayFromSection,
  extensionType,
  hasSelectedTray,
  initialExtensionValue,
  onValueChange,
  onExtensionTypeChange,
  t
}: Props): ReactElement {
  const {
    i18n: {language}
  } = useTranslation();
  const [value, setValue] = useState(`${initialExtensionValue}`);
  const [error, setError] = useState(false);
  const shouldShowConfirmDialog = useRef(hasSelectedTray);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const openConfirmDialog = useConfirmDialogContext();

  function handleValueChange(event: ChangeEvent<HTMLInputElement>) {
    const number = parseFloat(event.target.value);
    const error = isNaN(number) || number < 0 || number > 100;

    setValue(event.target.value);
    setError(error);
    if (!error) {
      onValueChange(Number(event.target.value));
    }
  }

  function changeExtensionType(value: ExtensionType) {
    onExtensionTypeChange(value);
    if (value === ExtensionType.Link) {
      setValue('50');
      onValueChange(50);
    } else if (value === ExtensionType.Rush) {
      setValue('100');
      onValueChange(100);
    } else {
      setValue('10');
      onValueChange(10);
    }
  }

  function handleExtensionTypeChange(event: ChangeEvent<HTMLInputElement>) {
    if (shouldShowConfirmDialog.current) {
      event.preventDefault();
      openConfirmDialog({
        title: t('common:confirmResetTray.title'),
        text: t('common:confirmResetTray.text'),
        onConfirm: function () {
          shouldShowConfirmDialog.current = false;
          deleteTrayFromSection();
          changeExtensionType(event.target.value as ExtensionType);
          setTimeout(() => inputRef.current?.focus(), 0);
        },
        onCancel: function () {
          setValue(`${initialExtensionValue}`);
        }
      });
    } else {
      changeExtensionType(event.target.value as ExtensionType);
    }
  }

  function onMouseDown() {
    if (shouldShowConfirmDialog.current) {
      openConfirmDialog({
        title: t('common:confirmResetTray.title'),
        text: t('common:confirmResetTray.text'),
        onConfirm: function () {
          shouldShowConfirmDialog.current = false;
          setTimeout(() => inputRef.current?.focus(), 0);
          deleteTrayFromSection();
        },
        onCancel: function () {
          setValue(`${initialExtensionValue}`);
        }
      });
    }
  }

  function handleSliderChange(event: any, newValue: number | number[]) {
    setValue(`${newValue as number}`);
  }

  function handleSliderChangeCommited(event: any, newValue: number | number[]) {
    if (shouldShowConfirmDialog.current) {
      openConfirmDialog({
        title: t('common:confirmResetTray.title'),
        text: t('common:confirmResetTray.text'),
        onConfirm: function () {
          shouldShowConfirmDialog.current = false;
          deleteTrayFromSection();
          onValueChange(Number(newValue));
        },
        onCancel: function () {
          setValue(`${initialExtensionValue}`);
        }
      });
    } else {
      onValueChange(Number(newValue));
    }
  }

  return (
    <FieldBox label={t('common:percentageExtension.label')}>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <FormControl fullWidth sx={{mb: 2}}>
            <RadioGroup
              row
              aria-labelledby="cable-placement-label"
              value={extensionType}
              name="radio-buttons-group"
              onChange={handleExtensionTypeChange}
              sx={{
                flexDirection: 'column',
                justifyContent: 'space-between'
              }}
            >
              <FormControlLabel
                value={ExtensionType.Normal}
                control={<Radio />}
                label={t('common:extensionType.normal')}
              />
              {language === 'es' && (
                <>
                  <Typography sx={{my: 1, fontSize: '0.8rem', fontWeight: 'bold'}}>
                    {t('common:rebt')}
                  </Typography>
                  <FormControlLabel
                    value={ExtensionType.Rush}
                    control={<Radio />}
                    label={t('common:itcbt14')}
                  />
                  <FormControlLabel
                    value={ExtensionType.Link}
                    control={<Radio />}
                    label={t('common:itcbt16')}
                  />
                </>
              )}
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            id="percentageExtension"
            label={t('common:percentageExtension.label')}
            helperText={
              error
                ? t('common:percentageExtension.error')
                : extensionType === ExtensionType.Normal
                ? t('common:percentageExtension.help')
                : null
            }
            error={error}
            value={value}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>
            }}
            onChange={handleValueChange}
            inputProps={{
              step: '5.0'
            }}
            type="number"
            fullWidth
            onMouseDown={onMouseDown}
            inputRef={inputRef}
            sx={{
              mb: 2
            }}
          />
          <Slider
            value={+value}
            min={0}
            max={100}
            step={10}
            marks
            aria-label={t('common:percentageExtension.label')}
            onChange={handleSliderChange}
            onChangeCommitted={handleSliderChangeCommited}
          />
        </Grid>
      </Grid>
    </FieldBox>
  );
}
