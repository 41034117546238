/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {ReactElement} from 'react';
import {FinishId} from 'interfaces/Finish';
import {
  Cable,
  CablesPlacement,
  ExtensionType,
  Section as SectionInterface,
  SectionCableId,
  SectionId,
  Tray
} from 'interfaces/Project';
import {System, SystemReference} from 'interfaces/System';
import {UseConfiguratorActions} from 'views/Calculator/hooks/useConfigurator';
import SectionView from './SectionView';

type Props = {
  section: SectionInterface;
  otherSections: SectionInterface[];
  systems: System[];
  configuratorActions: UseConfiguratorActions;
};

export default function Section({
  section,
  otherSections,
  systems,
  configuratorActions
}: Props): ReactElement {
  function handleSystemChange(traySystemId: SystemReference) {
    console.log('handleSystemChange', traySystemId)
    configuratorActions.updateSectionTraySystem(section.id, traySystemId);
  }

  function handleFinishChange(finishId: FinishId) {
    configuratorActions.updateSectionFinish(section.id, finishId);
  }

  function handleCableSelect(cable: Cable) {
    configuratorActions.addCableToSection(section.id, cable);
  }

  function handleNameChange(name: string) {
    configuratorActions.updateSectionName(section.id, name);
  }

  function handleNotesChange(notes: string) {
    configuratorActions.updateSectionNotes(section.id, notes);
  }

  function handleLengthChange(length: number) {
    configuratorActions.updateSectionLength(section.id, length);
  }

  function handleDistanceChange(distance: number) {
    configuratorActions.updateSectionDistance(section.id, distance);
  }

  function handleExtensionChange(extension: number) {
    configuratorActions.updateSectionExtension(section.id, extension);
  }

  function handleExtensionTypeChange(extensionType: ExtensionType) {
    configuratorActions.updateSectionExtensionType(section.id, extensionType);
  }

  function handleCableUnitsChange(sectionCableId: SectionCableId, units: number) {
    configuratorActions.updateSectionCableUnits(section.id, sectionCableId, units);
  }

  function handleCableDelete(sectionCableId: SectionCableId) {
    configuratorActions.deleteCableFromSection(section.id, sectionCableId);
  }

  function handleCablesCopy(to: SectionId) {
    configuratorActions.copySectionCablesToSection(section.id, to);
  }

  function handleCablesPlacementChange(placement: CablesPlacement) {
    configuratorActions.updateCablesPlacement(section.id, placement);
  }

  function deleteTrayFromSection() {
    configuratorActions.deleteTrayFromSection(section.id);
  }

  function updateTray(tray: Tray) {
    configuratorActions.updateSectionTray(section.id, tray);
  }

  return (
    <SectionView
      section={section}
      systems={systems}
      otherSections={otherSections}
      onSystemChange={handleSystemChange}
      onFinishChange={handleFinishChange}
      onCableSelect={handleCableSelect}
      onTraySelect={updateTray}
      onLengthChange={handleLengthChange}
      onDistanceChange={handleDistanceChange}
      onExtensionChange={handleExtensionChange}
      onExtensionTypeChange={handleExtensionTypeChange}
      onCablesPlacementChange={handleCablesPlacementChange}
      onNameChange={handleNameChange}
      onNotesChange={handleNotesChange}
      onCablesCopy={handleCablesCopy}
      onCableUnitsChange={handleCableUnitsChange}
      onCableDelete={handleCableDelete}
      deleteTrayFromSection={deleteTrayFromSection}
    />
  );
}
