import {ReactElement, useRef} from 'react';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material';
import {useTranslation} from 'react-i18next';
import useConfirmDialogContext from 'contexts/ConfirmDialogContext/useConfirmDialogContext';
import {Cable, SectionCable, SectionCableId} from 'interfaces/Project';
import formatNumber from 'utils/formatNumber';
import AddCableDialog from './components/AddCableDialog';
import CableUnits from './components/CableUnits';

type Props = {
  deleteTrayFromSection: () => void;
  hasSelectedTray: boolean;
  sectionCables: SectionCable[];
  onCableDelete: (sectionCableId: SectionCableId) => void;
  onCableSelect: (cable: Cable) => void;
  onCableUnitsChange: (sectionCableId: SectionCableId, units: number) => void;
};

export default function SectionCables({
  deleteTrayFromSection,
  hasSelectedTray,
  onCableUnitsChange,
  onCableDelete,
  onCableSelect,
  sectionCables
}: Props): ReactElement {
  const {t} = useTranslation('common');
  const shouldShowConfirmDialog = useRef(hasSelectedTray);
  const openConfirmDialog = useConfirmDialogContext();

  function handleCableDelete(sectionCableId: string) {
    if (shouldShowConfirmDialog.current) {
      openConfirmDialog({
        onConfirm: function () {
          shouldShowConfirmDialog.current = false;
          deleteTrayFromSection();
          onCableDelete(sectionCableId);
        },
        title: t('common:confirmResetTray.title'),
        text: t('common:confirmResetTray.text')
      });
    } else {
      onCableDelete(sectionCableId);
    }
  }

  return (
    <Box>
      <TableContainer
        sx={{mb: 2, borderColor: 'borderColor', borderWidth: 1, borderStyle: 'solid'}}
      >
        <Table sx={{minWidth: 650}} aria-label="cables">
          <TableHead sx={{bgcolor: 'grey.50'}}>
            <TableRow>
              <TableCell sx={{fontWeight: 'bold'}}>{t('common:manufacturer')}</TableCell>
              <TableCell sx={{fontWeight: 'bold'}}>{t('common:designation')}</TableCell>
              <TableCell sx={{fontWeight: 'bold'}}>{t('common:commercialBrand')}</TableCell>
              <TableCell sx={{fontWeight: 'bold'}}>{t('common:conductingSection')}</TableCell>
              <TableCell sx={{fontWeight: 'bold'}}>{t('common:externalDiameter')}</TableCell>
              <TableCell sx={{fontWeight: 'bold'}}>{t('common:weightKgByMeter')}</TableCell>
              <TableCell sx={{fontWeight: 'bold'}}>{t('common:units')}</TableCell>
              <TableCell sx={{fontWeight: 'bold'}}>{t('common:actions')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sectionCables.map((sectionCable, index) => (
              <TableRow
                key={sectionCable.sectionCableId}
                sx={{
                  bgcolor: sectionCable.hasDeprecatedInfo
                    ? '#ffffed'
                    : index % 2
                    ? 'grey.50'
                    : 'white',
                  '&:last-child td, &:last-child th': {border: 0}
                }}
              >
                <TableCell>{sectionCable.manufacturer}</TableCell>
                <TableCell>{sectionCable.name}</TableCell>
                <TableCell>{sectionCable.brand}</TableCell>
                <TableCell>{sectionCable.conductingSection}</TableCell>
                <TableCell>{`${formatNumber(sectionCable.diameter)}`}</TableCell>
                <TableCell>{`${formatNumber(sectionCable.weight)}`}</TableCell>
                <TableCell>
                  <CableUnits
                    deleteTrayFromSection={deleteTrayFromSection}
                    hasSelectedTray={hasSelectedTray}
                    initialValue={sectionCable.units}
                    onChange={(units: number) =>
                      onCableUnitsChange(sectionCable.sectionCableId, units)
                    }
                    t={t}
                  />
                </TableCell>
                <TableCell>
                  <Tooltip title={t('common:deleteCable')}>
                    <IconButton
                      size="small"
                      aria-label="remove"
                      onClick={() => handleCableDelete(sectionCable.sectionCableId)}
                    >
                      <DeleteOutlineIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {sectionCables.length === 0 && (
          <Typography
            sx={{
              p: 2,
              color: 'error.main'
            }}
          >
            {t('common:noCablesSelected')}
          </Typography>
        )}
      </TableContainer>
      <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
        <AddCableDialog
          defaultManufacturer={sectionCables[0]?.manufacturer}
          deleteTrayFromSection={deleteTrayFromSection}
          hasSelectedTray={hasSelectedTray}
          onCableSelect={onCableSelect}
        />
      </Box>
    </Box>
  );
}
