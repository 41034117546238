import {SystemReference} from './System';

export type ProjectId = number;

export interface Flange {
  id: number;
  name: number;
}

interface Catalog {
  id: number;
  reference: string;
  name: string;
  link: string;
}

export interface Tray {
  id: number;
  name: string;
  description: string | null;
  clientReference: string;
  usefulSection: number;
  image: string;
  systemId: number;
  maxLoad: number | null;
  flange: Flange;
  totalSection: number | null;
  cablesWeight: number | null;
  url: string | null;
  bc3: string | null;
  rvt: string | null;
  acae: string | null;
  xls: string | null;
  videos: Catalog[];
  catalogs: Catalog[];
}

export type SectionCableId = string;

export interface SectionCable extends Cable {
  sectionCableId: SectionCableId;
  units: number;
  hasDeprecatedInfo: boolean;
}

export interface CableManufacturer {
  name: string;
}

export type CableId = number;

export interface Cable {
  id: CableId;
  manufacturer: string;
  name: string;
  brand: string;
  conductingSection: string;
  diameter: number;
  weight: number;
  notes: null | string;
  isEditable: boolean;
}

export enum ExtensionType {
  Normal = 'normal',
  Rush = 'rush',
  Link = 'link'
}

export enum CablesPlacement {
  WithLayers = 'conCapa',
  WithSeparation = 'conSep',
  WithoutSeparation = 'sinSep'
}

export type SectionId = string;

export interface Section {
  id: SectionId;
  cablesPlacement: CablesPlacement;
  notes: string | null;
  distance: number;
  extensionType: ExtensionType;
  extension: number;
  finishId: number | null;
  length: number;
  maxHeight: number;
  name: string | null;
  totalSection: number;
  tray: Tray | null;
  traySystemId: SystemReference;
  cables: SectionCable[];
  position: number;
}

export interface ProjectTeaser {
  id: ProjectId;
  name: string;
  description: string | null;
}

export interface Project {
  id: ProjectId;
  name: string;
  description: string | null;
  sections: Section[];
  hasDeprecatedInfo: boolean;
}
