/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export default class ApiError<TData = any> extends Error {
  constructor(
    public message: string,
    public code: string | number | null = null,
    public data: TData | null = null
  ) {
    super(message);
  }
}
