import {ReactElement, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import useConfirmDialogContext from 'contexts/ConfirmDialogContext/useConfirmDialogContext';
import useModalState from 'hooks/useModalState';
import {Cable, CableManufacturer} from 'interfaces/Project';
import AddCableDialogView from './AddCableDialogView';

type Props = {
  defaultManufacturer?: string;
  deleteTrayFromSection: () => void;
  hasSelectedTray: boolean;
  manufacturers: [CableManufacturer, ...CableManufacturer[]];
  onCableSelect: (cable: Cable) => void;
};

export default function AddCableDialog({
  defaultManufacturer,
  deleteTrayFromSection,
  hasSelectedTray,
  manufacturers,
  onCableSelect
}: Props): ReactElement {
  const {t} = useTranslation(['common']);
  const {isOpened, open, close} = useModalState(false);
  const shouldShowConfirmDialog = useRef(hasSelectedTray);
  const openConfirmDialog = useConfirmDialogContext();

  function handleCableSelect(cable: Cable) {
    onCableSelect(cable);
    close();
  }

  function handleOpen() {
    if (shouldShowConfirmDialog.current) {
      openConfirmDialog({
        title: t('common:confirmResetTray.title'),
        text: t('common:confirmResetTray.text'),
        onConfirm: function () {
          shouldShowConfirmDialog.current = false;
          deleteTrayFromSection();
          open();
        }
      });
    } else {
      open();
    }
  }

  return (
    <AddCableDialogView
      defaultManufacturer={defaultManufacturer}
      onCableSelect={handleCableSelect}
      manufacturers={manufacturers}
      opened={isOpened}
      onOpenClicked={handleOpen}
      onCloseClicked={close}
    />
  );
}
