import {QueryKey, useQuery, QueryObserverResult, QueryObserverOptions} from 'react-query';
import ExpiredJwtError from 'classes/ExpiredJwtError';
import {useBackendClient} from 'contexts/BackendClientContext';
import {ApiQueryStatus} from 'interfaces/ApiRequestStatus';
import ApiResponse from 'interfaces/ApiResponse';
import useTryToRefreshJwtToken from './useTryToRefreshJwtToken';

const defaultOptions = {
  staleTime: 0,
  refetchOnWindowFocus: false,
  enabled: true
};

type RequestOptions = {
  method: 'GET' | 'POST' | 'DELETE' | 'PUT' | 'PATCH';
  path: string;
  body?: Record<string, any> | null;
};

export type ApiQueryOptions<TData, TQueryKey extends QueryKey = QueryKey> = Omit<
  QueryObserverOptions<any, Error, ApiResponse<TData>, any, TQueryKey>,
  'queryFn' | 'onError'
> & {
  queryKey: QueryKey;
};

type UseApiQueryOptions<TData> = {
  requestOptions: RequestOptions;
  queryOptions: ApiQueryOptions<TData>;
};

export type UseApiQuery<TData> = {
  apiQueryStatus: ApiQueryStatus;
  apiResponse?: ApiResponse<TData>;
  error?: Error | null;
  refetch: () => Promise<QueryObserverResult<ApiResponse<TData>, Error>>;
  queryKey: QueryKey;
  remove: () => void;
};

export default function useApiQuery<TData>({
  queryOptions,
  requestOptions
}: UseApiQueryOptions<TData>): UseApiQuery<TData> {
  const backendClient = useBackendClient();
  const {queryKey, ...restOfOptions} = queryOptions;
  const [{tryToRefreshJwtToken, isRefreshing: isRefeshingJwtToken}] = useTryToRefreshJwtToken([
    queryKey
  ]);

  const {
    data: apiResponse,
    error,
    refetch,
    status,
    fetchStatus,
    remove
  } = useQuery<ApiResponse<TData>, Error>({
    queryKey,
    queryFn: () => backendClient.sendRequest<TData>(requestOptions),

    retry: (failureCount, error) => !(error instanceof ExpiredJwtError) && failureCount < 3,
    onError: tryToRefreshJwtToken,
    ...defaultOptions,
    ...restOfOptions
  });

  const apiQueryStatus = {
    dataStatus: {
      isLoading: status === 'loading',
      isSuccess: status === 'success',
      isError: status === 'error'
    },
    fetchStatus: {
      isPaused: fetchStatus === 'paused',
      isFetching: fetchStatus === 'fetching' || isRefeshingJwtToken,
      isIdle: fetchStatus === 'idle'
    }
  };

  return {apiQueryStatus, apiResponse, error, refetch, queryKey, remove};
}
