import {ReactElement} from 'react';
import ErrorIndicator from 'components/shared/ui/ErrorIndicator';
import Calculator from './Calculator';
import useProjects from './hooks/useProjects';
import useSystems from './hooks/useSystems';

export default function CalculatorContainer(): ReactElement {
  const {apiQueryStatus: getProjectsStatus, apiResponse: getProjectsResponse} = useProjects();

  const {apiQueryStatus: getSystemsStatus, apiResponse: getSystemsResponse} = useSystems();

  if (getProjectsStatus.dataStatus.isError || !getSystemsResponse) {
    return <ErrorIndicator />;
  }

  if (getSystemsStatus.dataStatus.isError || !getProjectsResponse) {
    return <ErrorIndicator />;
  }

  return <Calculator projects={getProjectsResponse.data} systems={getSystemsResponse.data} />;
}
