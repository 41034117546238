import {useRef, useMemo} from 'react';
import {QueryKey, useQueryClient} from 'react-query';
import ExpiredJwtError from 'classes/ExpiredJwtError';
import {UnsubscribeRefreshTokenObserver, useBackendClient} from 'contexts/BackendClientContext';

type UseRefreshTokenObserver = {
  tryToRefreshJwtToken: (error: any) => void;
  isRefreshing: boolean;
};

export default function useTryToRefreshJwtToken(keys: QueryKey[]): UseRefreshTokenObserver[] {
  const backendClient = useBackendClient();
  const queryClient = useQueryClient();
  const unsubscribeRefreshTokenObservers = useRef<(null | UnsubscribeRefreshTokenObserver)[]>(
    Array(keys.length).fill(null)
  );

  const tryToRefreshJwtToken = useMemo<UseRefreshTokenObserver[]>(
    function () {
      function createTryToRefreshJwtToken(key: QueryKey, index: number) {
        return function (error: any) {
          function refreshTokenObserver(success: boolean): void {
            if (!success) {
              throw new Error('Expired');
            }
            if (key) {
              queryClient.invalidateQueries(key);
            }

            const unsubscribe = unsubscribeRefreshTokenObservers.current[index];
            if (unsubscribe) {
              unsubscribe();
              unsubscribeRefreshTokenObservers.current[index] = null;
            }
          }

          if (error instanceof ExpiredJwtError && backendClient.hasAuthTokens()) {
            unsubscribeRefreshTokenObservers.current[index] =
              backendClient.requestRefreshToken(refreshTokenObserver);
          }
        };
      }
      return keys.map<UseRefreshTokenObserver>((key, index) => ({
        tryToRefreshJwtToken: createTryToRefreshJwtToken(key, index),
        isRefreshing: Boolean(unsubscribeRefreshTokenObservers.current[index])
      }));
    },
    [keys, queryClient, backendClient]
  );

  return tryToRefreshJwtToken;
}
