import {ReactElement} from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Grid,
  styled
} from '@mui/material';
import {useTranslation} from 'react-i18next';
import TrayInformation from 'components/shared/tray/TrayInformation';
import {Tray} from 'interfaces/Project';
type Props = {
  tray: Tray;
  onCancel: () => void;
  onConfirm: () => void;
};

const StyledImg = styled('img')(({theme}) => ({
  display: 'block',
  maxWidth: '100%',
  height: 'auto',
  borderStyle: 'solid',
  borderWidth: 1,
  borderColor: theme.palette.borderColor,
  borderRadius: 4
}));

export default function ShowTray({tray, onCancel, onConfirm}: Props): ReactElement {
  const {t} = useTranslation('common');
  return (
    <Dialog open maxWidth="lg" fullWidth>
      <DialogTitle>
        {tray.name} - {tray.clientReference}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={4}>
            <StyledImg src={tray.image} alt={tray.name} />
          </Grid>
          <Grid item xs={12} lg={4}>
            <TrayInformation t={t} tray={tray} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>{t('common:cancel')}</Button>
        <Button onClick={onConfirm} variant="contained">
          {t('common:selectTray')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
