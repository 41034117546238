import {ReactElement, ReactNode, useCallback, useRef, useState} from 'react';
import useModalState from 'hooks/useModalState';
import ConfirmDialogContext, {
  ConfirmDialogContextValue,
  OnCancel,
  OnConfirm
} from './ConfirmDialogContext';
import ConfirmDialog from './components/ConfirmDialog';

type Props = {
  children: ReactNode;
};

export default function ConfirmDialogContextProvider({children}: Props): ReactElement {
  const {isOpened, open: openModal, close: closeModal} = useModalState();
  const onConfirm = useRef<OnConfirm | null>(null);
  const onCancel = useRef<OnCancel | null>(null);
  const [dialogContent, setDialogContent] = useState({
    title: '',
    text: ''
  });

  const open = useCallback<ConfirmDialogContextValue>(
    function ({onConfirm: onConfirmCallback, onCancel: onCancelCallback, title, text}) {
      setDialogContent({
        title,
        text
      });
      openModal();
      if (onConfirmCallback) {
        onConfirm.current = onConfirmCallback;
      }
      if (onCancelCallback) {
        onCancel.current = onCancelCallback;
      }
    },
    [openModal]
  );

  function handleConfirm() {
    closeModal();
    onConfirm.current?.();
  }

  function handleCancel() {
    closeModal();
    onCancel.current?.();
  }

  return (
    <ConfirmDialogContext.Provider value={open}>
      {children}
      <ConfirmDialog
        isOpened={isOpened}
        onClose={handleCancel}
        onConfirm={handleConfirm}
        text={dialogContent.text}
        title={dialogContent.title}
      />
    </ConfirmDialogContext.Provider>
  );
}
