import {useContext} from 'react';
import BackendClientContext, {defaultValue} from './BackendClientContext';
import {BackendClient} from './types';

export default function useBackendClient(): BackendClient {
  const value = useContext(BackendClientContext);
  if (value === defaultValue) {
    throw new Error('no value provided for context');
  }
  return value as BackendClient;
}
