import {ReactElement} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';

type Props = {
  isOpened: boolean;
  onClose: () => void;
  onConfirm: () => void;
  text: string;
  title: string;
};

export default function ConfirmDialog({
  isOpened,
  onClose,
  onConfirm,
  text,
  title
}: Props): ReactElement {
  const {t} = useTranslation(['common']);
  return (
    <Dialog open={isOpened} maxWidth="lg" fullWidth onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Typography>{text}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary" variant="text">
          {t('common:cancel')}
        </Button>
        <Button onClick={onConfirm} color="secondary" variant="contained">
          {t('common:confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
