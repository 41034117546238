import {ReactElement} from 'react';
import AddIcon from '@mui/icons-material/Add';
import {Button, Dialog, DialogContent, DialogTitle} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {Cable, CableManufacturer} from 'interfaces/Project';
import SelectCable from './components/SelectCable';

type Props = {
  defaultManufacturer?: string;
  onCableSelect: (cable: Cable) => void;
  manufacturers: [CableManufacturer, ...CableManufacturer[]];
  opened: boolean;
  onOpenClicked: () => void;
  onCloseClicked: () => void;
};

export default function AddCableDialogView({
  defaultManufacturer,
  opened,
  onOpenClicked,
  onCloseClicked,
  onCableSelect,
  manufacturers
}: Props): ReactElement {
  const {t} = useTranslation('common');

  return (
    <>
      <Button endIcon={<AddIcon />} onClick={onOpenClicked} variant="outlined">
        {t('common:addCable')}
      </Button>
      <Dialog onClose={onCloseClicked} open={opened} maxWidth="lg" fullWidth>
        <DialogTitle>{t('common:selectCable')}</DialogTitle>
        <DialogContent>
          <SelectCable
            defaultManufacturer={defaultManufacturer}
            manufacturers={manufacturers}
            onCableSelect={onCableSelect}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
