import {ReactElement, useEffect, useState} from 'react';
import {Alert, Box, Button, Grid, Paper, SelectChangeEvent, Snackbar} from '@mui/material';
import {useTranslation} from 'react-i18next';
import LoadingIndicator from 'components/shared/ui/LoadingIndicator';
import {ApiMutationStatus, ApiQueryStatus} from 'interfaces/ApiRequestStatus';
import {Project} from 'interfaces/Project';
import {System} from 'interfaces/System';
import ProjectConfigurator from './components/ProjectConfigurator';
import SelectProject from './components/SelectProject';
import {UseConfiguratorActions} from './hooks/useConfigurator';
import {UseSaveProject} from './hooks/useConfigurator/useSaveProject';

type Props = {
  selectProjectForm: {
    value: string | null;
    options: Project[];
  };
  onSelectProject: (event: SelectChangeEvent) => void;
  selectFirstProject: () => void;
  editableProject: Project | null;
  systems: System[];
  configuratorActions: UseConfiguratorActions;
  getProjectStatus: ApiQueryStatus;
  saveProject: UseSaveProject;
  onCreate: () => void;
  createProjectRequestStatus: ApiMutationStatus;
};

export default function CalculatorView({
  selectProjectForm,
  onSelectProject,
  selectFirstProject,
  editableProject,
  systems,
  configuratorActions,
  getProjectStatus,
  saveProject,
  onCreate,
  createProjectRequestStatus
}: Props): ReactElement {
  const {t} = useTranslation('common');
  const [isErrorSnackbarVisible, setIsErrorSnackbarVisible] = useState(false);

  function handleCloseErrorSnackbar() {
    setIsErrorSnackbarVisible(false);
  }

  useEffect(
    function () {
      if (saveProject.requestStatus.isError) {
        setIsErrorSnackbarVisible(true);
      }
    },
    [saveProject.requestStatus.isError]
  );

  return (
    <Box>
      <Grid container mb={4} spacing={4} alignItems="center">
        <Grid item xs={12} lg={10}>
          <SelectProject
            t={t}
            onSelectProject={onSelectProject}
            selectProjectForm={selectProjectForm}
          />
        </Grid>
        <Grid item xs={12} lg={2}>
          <Button onClick={onCreate} variant="outlined" fullWidth color="secondary">
            {t('common:createProject')}
          </Button>
        </Grid>
      </Grid>
      {editableProject && !getProjectStatus.fetchStatus.isFetching && (
        <Paper elevation={3} sx={{mb: 4, overflow: 'hidden'}}>
          <ProjectConfigurator
            editableProject={editableProject}
            systems={systems}
            saveProject={saveProject}
            configuratorActions={configuratorActions}
            onProjectAction={selectFirstProject}
          />
        </Paper>
      )}
      {getProjectStatus.dataStatus.isLoading && (
        <LoadingIndicator centered text={t('common:loadingProject')} />
      )}
      {createProjectRequestStatus.isLoading && (
        <LoadingIndicator text={t('common:creatingProject')} />
      )}
      <Snackbar
        open={isErrorSnackbarVisible}
        autoHideDuration={6000}
        onClose={handleCloseErrorSnackbar}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
      >
        <Alert severity="error" sx={{width: '100%'}}>
          {t('common:saveProjectError')}
        </Alert>
      </Snackbar>
    </Box>
  );
}
