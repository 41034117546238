import {MouseEvent, ReactElement, useState} from 'react';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MenuIcon from '@mui/icons-material/Menu';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import SaveIcon from '@mui/icons-material/Save';
import {
  Box,
  Typography,
  IconButton,
  CircularProgress,
  Menu,
  MenuItem,
  ListItemIcon,
  Divider,
  Snackbar,
  Alert,
  Tooltip
} from '@mui/material';
import {TFunction} from 'react-i18next';
import {ApiMutationStatus} from 'interfaces/ApiRequestStatus';
import {Project} from 'interfaces/Project';

type Props = {
  project: Project;
  t: TFunction;
  onGeneratePdf: () => void;
  requestPdfStatus: ApiMutationStatus;
  onClone: () => void;
  cloneProjectRequestStatus: ApiMutationStatus;
  onDelete: () => void;
  deleteProjectRequestStatus: ApiMutationStatus;
  onSave: () => void;
  saveProjectRequestStatus: ApiMutationStatus;
};

export default function ProjectHeaderView({
  project,
  t,
  onGeneratePdf,
  requestPdfStatus,
  onClone,
  cloneProjectRequestStatus,
  onDelete,
  deleteProjectRequestStatus,
  onSave,
  saveProjectRequestStatus
}: Props): ReactElement {
  const [projectMenuElement, setProjectMenuElement] = useState<null | HTMLElement>(null);
  const isProjectMenuOpened = Boolean(projectMenuElement);

  function handleOpenProjectMenu(event: MouseEvent<HTMLButtonElement>) {
    setProjectMenuElement(event.currentTarget);
  }

  function handleCloseProjectMenu() {
    setProjectMenuElement(null);
  }

  const someRequestIsSending =
    requestPdfStatus.isLoading ||
    saveProjectRequestStatus.isLoading ||
    deleteProjectRequestStatus.isLoading;

  return (
    <>
      <Box sx={{mb: 4}}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            px: 2,
            py: 1,
            bgcolor: 'grey.500'
          }}
        >
          <Typography
            variant="h2"
            color="white"
            sx={{
              flex: 1
            }}
          >
            {project.name}
          </Typography>
          <Box>
            <Tooltip title={t('common:openMenu')}>
              <IconButton
                size="large"
                aria-controls={isProjectMenuOpened ? 'projectMenu' : undefined}
                aria-haspopup="true"
                aria-expanded={isProjectMenuOpened ? 'true' : undefined}
                onClick={handleOpenProjectMenu}
              >
                <MenuIcon fontSize="large" sx={{color: 'white'}} />
              </IconButton>
            </Tooltip>
            <Tooltip title={t('common:saveProject')}>
              <IconButton
                size="large"
                aria-label="save"
                onClick={onSave}
                disabled={someRequestIsSending}
              >
                {saveProjectRequestStatus.isLoading ? (
                  <CircularProgress sx={{color: 'white'}} />
                ) : (
                  <SaveIcon fontSize="large" sx={{color: 'white'}} />
                )}
              </IconButton>
            </Tooltip>

            <Menu
              id="projectMenu"
              anchorEl={projectMenuElement}
              open={isProjectMenuOpened}
              onClose={handleCloseProjectMenu}
              MenuListProps={{
                'aria-labelledby': 'basic-button'
              }}
            >
              <MenuItem
                onClick={function () {
                  onClone();
                  handleCloseProjectMenu();
                }}
              >
                <ListItemIcon>
                  <FileCopyIcon fontSize="small" />
                </ListItemIcon>
                {t('common:cloneCurrentProject')}
              </MenuItem>
              <Divider sx={{my: 0.5}} />
              <MenuItem
                onClick={function () {
                  onGeneratePdf();
                  handleCloseProjectMenu();
                }}
              >
                <ListItemIcon>
                  <PictureAsPdfIcon fontSize="small" />
                </ListItemIcon>
                {t('common:generatePdf')}
              </MenuItem>
              <Divider sx={{my: 0.5}} />
              <MenuItem
                onClick={function () {
                  onDelete();
                  handleCloseProjectMenu();
                }}
              >
                <ListItemIcon>
                  <DeleteOutlineIcon fontSize="small" />
                </ListItemIcon>
                {t('common:delete')}
              </MenuItem>
            </Menu>
          </Box>
        </Box>
        {requestPdfStatus.isError && (
          <Alert severity="error" sx={{width: '100%'}}>
            {t('common:generatePdfError')}
          </Alert>
        )}
        {deleteProjectRequestStatus.isError && (
          <Alert severity="error" sx={{width: '100%'}}>
            {t('common:deleteProject.error')}
          </Alert>
        )}
      </Box>
      <Snackbar
        open={cloneProjectRequestStatus.isLoading}
        autoHideDuration={6000}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
      >
        <Alert severity="info" sx={{width: '100%'}}>
          {t('common:creatingProject')}
        </Alert>
      </Snackbar>
    </>
  );
}
