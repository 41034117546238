import {ReactElement} from 'react';
import {Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {Section} from 'interfaces/Project';
import getSectionName from 'utils/getSectionName';

type Props = {
  sections: Section[];
  selectedSectionId: string;
  onChange: (to: string) => void;
};

export default function SelectSection({
  sections,
  selectedSectionId,
  onChange
}: Props): ReactElement {
  const {t} = useTranslation('common');

  function handleChange(event: SelectChangeEvent) {
    onChange(event.target.value);
  }

  return (
    <Box
      sx={{
        mt: 2
      }}
    >
      <FormControl fullWidth>
        <InputLabel id="select-section-label">{t('common:section')}</InputLabel>
        <Select
          labelId="select-section-label"
          id="select-section"
          label={t('common:section')}
          value={selectedSectionId}
          onChange={handleChange}
        >
          {sections.map((section, index) => (
            <MenuItem value={`${section.id}`} key={`section--${section.id}`}>
              {getSectionName(section, index + 1, t)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
