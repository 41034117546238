import {ReactElement} from 'react';
import {Box} from '@mui/material';
import {useTranslation} from 'react-i18next';
import ListItem from './ListItem';

type Props = {
  isMenuOpened: boolean;
};

export default function Menu({isMenuOpened}: Props): ReactElement {
  const {t} = useTranslation(['menu']);
  return (
    <Box
      sx={{
        display: {
          xs: isMenuOpened ? 'block' : 'none',
          md: 'block'
        },
        p: {
          xs: 2,
          md: 0
        },
        borderTopColor: 'primary.main',
        borderTopWidth: {
          xs: 4,
          md: 0
        },
        borderTopStyle: 'solid',
        boxShadow: {
          xs: 1,
          md: 0
        }
      }}
    >
      <ul id="menu-menu-principal-espanol" className="nav nav-menu">
        <ListItem text={t('menu:pemsa.label')}>
          <ul className="sub-menu">
            <li>
              <a href={t('menu:company.link')}>{t('menu:company.label')}</a>
            </li>
            <li>
              <a href={t('menu:news.link')}>{t('menu:news.label')}</a>
            </li>
          </ul>
        </ListItem>
        <ListItem text={t('menu:onlineCatalogue.label')} href={t('menu:onlineCatalogue.link')} />
        <ListItem text={t('menu:resources.label')}>
          <ul className="sub-menu">
            <li>
              <a href={t('menu:bim.link')}>{t('menu:bim.label')}</a>
            </li>
            <li>
              <a href={t('menu:videoBlog.link')}>{t('menu:videoBlog.label')}</a>
            </li>
            <li>
              <a href={t('menu:downloads.link')}>{t('menu:downloads.label')}</a>
            </li>
            <li>
              <a href={t('menu:expertOne.link')}>{t('menu:expertOne.label')}</a>
            </li>
          </ul>
        </ListItem>
        <ListItem text={t('menu:users.label')}>
          <ul className="sub-menu">
            <li>
              <a href={t('menu:technicalSpecifications.link')}>
                {t('menu:technicalSpecifications.label')}
              </a>
            </li>
            <li>
              <a href={t('menu:technicalDocumentation.link')}>
                {t('menu:technicalDocumentation.label')}
              </a>
            </li>
            <li>
              <a href={t('menu:rebt.link')}>{t('menu:rebt.label')}</a>
            </li>
            <li>
              <a href={t('menu:presto.link')}>{t('menu:presto.label')}</a>
            </li>
          </ul>
        </ListItem>
        <ListItem text={t('menu:contact.label')}>
          <ul className="sub-menu">
            <li>
              <a href={t('menu:location.link')}>{t('menu:location.label')}</a>
            </li>
            <li>
              <a href={t('menu:uk.link')}>{t('menu:uk.label')}</a>
            </li>
            <li>
              <a href={t('menu:france.link')}>{t('menu:france.label')}</a>
            </li>
            <li>
              <a href={t('menu:portugal.link')}>{t('menu:portugal.label')}</a>
            </li>
            <li>
              <a href={t('menu:latam.link')}>{t('menu:latam.label')}</a>
            </li>
            <li>
              <a href={t('menu:mexico.link')}>{t('menu:mexico.label')}</a>
            </li>
            <li>
              <a href={t('menu:commercialAreas.link')}>{t('menu:commercialAreas.label')}</a>
            </li>
            <li>
              <a href={t('menu:export.link')}>{t('menu:export.label')}</a>
            </li>
            <li>
              <a href={t('menu:subsidiaries.link')}>{t('menu:subsidiaries.label')}</a>
            </li>
            <li>
              <a href={t('menu:customerSupport.link')}>{t('menu:customerSupport.label')}</a>
            </li>
          </ul>
        </ListItem>
      </ul>
    </Box>
  );
}
