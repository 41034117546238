import {ReactElement} from 'react';
import {Box} from '@mui/material';
import Typography from '@mui/material/Typography';
import {useTranslation} from 'react-i18next';

function ErrorIndicator(): ReactElement {
  const {t} = useTranslation(['common']);
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%'
      }}
    >
      <Typography color="error">{t('common:apiSuspense.error')}</Typography>
    </Box>
  );
}

export default ErrorIndicator;
