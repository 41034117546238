import {ReactElement} from 'react';
import {Box} from '@mui/material';
import {LoadingIcon} from './LoadingIndicator';

export default function AppLoading(): ReactElement {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh'
      }}
    >
      <LoadingIcon />
    </Box>
  );
}
