import {MouseEvent, ReactElement, useState} from 'react';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import HelpIcon from '@mui/icons-material/Help';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PersonIcon from '@mui/icons-material/Person';
import {
  Box,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  styled,
  Typography
} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {useQueryClient} from 'react-query';
import LoadingIndicator from 'components/shared/ui/LoadingIndicator';
import {USER} from 'const/backend';
import {Locale, LOCALES} from 'const/locales';
import useUserContext from 'contexts/UserContext/useUserContext';
import useApiMutation from 'hooks/useApiMutation';
import logo from './images/logo.png';

const StyledImg = styled('img')(({theme}) => ({
  display: 'block',
  maxWidth: '50%',
  height: 'auto',
  marginLeft: 'auto',
  marginRight: 'auto',
  [theme.breakpoints.up('lg')]: {
    maxWidth: '100%'
  }
}));

export default function Header(): ReactElement {
  const queryClient = useQueryClient();
  const {t, i18n} = useTranslation('common');
  const [helpMenuElement, setHelpMenuElement] = useState<null | HTMLElement>(null);
  const [localeMenuElement, setLocaleMenuElement] = useState<null | HTMLElement>(null);
  const isHelpMenuOpened = Boolean(helpMenuElement);
  const isLocaleMenuOpened = Boolean(localeMenuElement);
  const user = useUserContext();
  const {mutate: patchUser, apiMutationStatus: patchUserRequestStatus} = useApiMutation<{
    name: string;
    locale: string;
  }>();

  function handleOpenHelpMenu(event: MouseEvent<HTMLButtonElement>) {
    setHelpMenuElement(event.currentTarget);
  }

  function handleCloseHelpMenu() {
    setHelpMenuElement(null);
  }

  function handleOpenLocaleMenu(event: MouseEvent<HTMLButtonElement>) {
    setLocaleMenuElement(event.currentTarget);
  }

  function handleCloseLocaleMenu() {
    setLocaleMenuElement(null);
  }

  function handleSelectLocale(locale: Locale) {
    setLocaleMenuElement(null);
    patchUser(
      {
        path: USER,
        method: 'PATCH',
        body: {
          locale
        }
      },
      {
        onSuccess: function (response) {
          queryClient.invalidateQueries();
          i18n.changeLanguage(locale);
        }
      }
    );
  }

  const currentLocale = LOCALES.find((locale) => user.locale === locale.id) || LOCALES[0];

  return (
    <Box>
      <Box
        sx={{
          position: 'relative'
        }}
      >
        <Box
          sx={{
            position: {
              xs: 'static',
              lg: 'absolute'
            },
            top: 0,
            right: 0,
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            mb: {
              xs: 2,
              lg: 0
            }
          }}
        >
          <Typography>{t('common:welcome', {fullname: user.name})}</Typography>
          <IconButton
            component="a"
            href="https://www.pemsa-rejiband.com/wp-admin/profile.php"
            target="_blank"
          >
            <PersonIcon />
          </IconButton>
          <IconButton
            id="help-menu-button"
            onClick={handleOpenHelpMenu}
            aria-controls={isHelpMenuOpened ? 'help-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={isHelpMenuOpened ? 'true' : undefined}
          >
            <HelpIcon />
          </IconButton>
          <Menu
            id="help-menu"
            anchorEl={helpMenuElement}
            open={isHelpMenuOpened}
            onClose={handleCloseHelpMenu}
            MenuListProps={{
              'aria-labelledby': 'help-menu-button'
            }}
          >
            <MenuItem
              component="a"
              href="https://www.pemsa-rejiband.com/wp-content/uploads/2016/10/PemsaPro-Online-Guia-de-Usuario.pdf"
              target="_blank"
              onClick={handleCloseHelpMenu}
            >
              {t('common:userGuide')}
            </MenuItem>
            <MenuItem
              component="a"
              href="https://www.youtube.com/watch?v=FuZmlfohpjU&feature=youtu.be"
              target="_blank"
              onClick={handleCloseHelpMenu}
            >
              {t('common:videoTutorial')}
            </MenuItem>
          </Menu>
          <IconButton
            id="locale-menu-button"
            onClick={handleOpenLocaleMenu}
            aria-controls={isLocaleMenuOpened ? 'locale-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={isLocaleMenuOpened ? 'true' : undefined}
          >
            <img src={currentLocale.flag} alt={currentLocale.name} />
            <KeyboardArrowDownIcon />
          </IconButton>
          <Menu
            id="help-menu"
            anchorEl={localeMenuElement}
            open={isLocaleMenuOpened}
            onClose={handleCloseLocaleMenu}
            MenuListProps={{
              'aria-labelledby': 'locale-menu-button'
            }}
          >
            {LOCALES.map((locale) => (
              <MenuItem key={locale.id} onClick={() => handleSelectLocale(locale.id)}>
                <img src={locale.flag} alt={locale.name} />
                &nbsp;<Typography>{locale.name}</Typography>
              </MenuItem>
            ))}
          </Menu>
          <IconButton component="a" href="https://www.pemsa-rejiband.com/" target="_blank">
            <ExitToAppIcon />
          </IconButton>
        </Box>
        <Grid container>
          <Grid item xs={12} lg={2}>
            <StyledImg src={logo} alt="Pemsa Pro" />
          </Grid>
        </Grid>
        <Typography
          color="primary"
          variant="h1"
          textAlign="center"
          sx={{
            fontSize: {
              xs: 'h6.fontSize',
              lg: 'h1.fontSize'
            }
          }}
        >
          {t('common:pageTitle')}
        </Typography>
        <Dialog open={patchUserRequestStatus.isLoading}>
          <DialogContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <LoadingIndicator text={t('common:changingLocale')} />
          </DialogContent>
        </Dialog>
      </Box>
    </Box>
  );
}
