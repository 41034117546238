import {ReactElement} from 'react';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails,
  Box,
  Typography,
  IconButton,
  AccordionSummaryProps,
  styled,
  AccordionProps,
  Tooltip
} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {Section as ISection} from 'interfaces/Project';
import {System} from 'interfaces/System';
import getSectionName from 'utils/getSectionName';
import {UseConfiguratorActions} from 'views/Calculator/hooks/useConfigurator';
import Section from './Section';

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{fontSize: '0.9rem'}} />}
    {...props}
  />
))(({theme}) => ({
  backgroundColor: theme.palette.grey[100],
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)'
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1)
  }
}));

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({theme}) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0
  },
  '&:before': {
    display: 'none'
  }
}));

type Props = {
  section: ISection;
  configuratorActions: UseConfiguratorActions;
  index: number;
  systems: System[];
  otherSections: ISection[];
};

function SectionAccordion({
  section,
  configuratorActions,
  systems,
  otherSections,
  index
}: Props): ReactElement {
  const {t} = useTranslation(['common']);
  return (
    <Accordion key={`section--${section.id}`} elevation={0}>
      <AccordionSummary
        aria-controls={`section-${section.id}-content`}
        id={`section-${section.id}-header`}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            minWidth: '100%'
          }}
        >
          <Typography variant="h4">{getSectionName(section, index + 1, t)}</Typography>
          <Tooltip title={t('common:deleteSection')}>
            <IconButton
              size="small"
              aria-label="remove"
              onClick={() => configuratorActions.deleteSection(section.id)}
            >
              <DeleteOutlineIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={{pt: 4}}>
        <Section
          section={section}
          systems={systems}
          configuratorActions={configuratorActions}
          otherSections={otherSections}
        />
      </AccordionDetails>
    </Accordion>
  );
}

export default SectionAccordion;
