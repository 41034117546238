import {styled} from '@mui/material';

const TrayImage = styled('img')(({theme}) => ({
  display: 'block',
  maxWidth: '100%',
  height: 'auto',
  borderStyle: 'solid',
  borderWidth: 1,
  borderColor: theme.palette.borderColor,
  borderRadius: 4
}));

export default TrayImage;
