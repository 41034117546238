import {createContext} from 'react';

export interface User {
  readonly name: string;
  readonly locale: string;
}

export type UserContextValue = User;

const UserContext = createContext<UserContextValue>({
  name: '',
  locale: ''
});

export default UserContext;
