import {ReactElement} from 'react';
import LoadingBox from './LoadingBox';
import LoadingIcon from './LoadingIcon';
import LoadingText from './LoadingText';

type Props = {
  centered?: boolean;
  text?: string;
};

export default function LoadingIndicator({text, centered}: Props): ReactElement {
  return (
    <LoadingBox centered={centered}>
      <LoadingIcon />
      <LoadingText text={text} />
    </LoadingBox>
  );
}
