import {ReactElement, Suspense} from 'react';
import {Box, Container, Skeleton, ThemeProvider} from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import {QueryClient, QueryClientProvider} from 'react-query';
import PemsaHeader from 'components/shared/PemsaHeader';
import AppLoading from 'components/shared/ui/AppLoading';
import {BackendClientContextProvider} from 'contexts/BackendClientContext';
import {ConfirmDialogContextProvider} from 'contexts/ConfirmDialogContext';
import UserContextProvider from 'contexts/UserContext/UserContextProvider';
import theme from 'theme/theme';
import Calculator from 'views/Calculator';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      networkMode: 'always',
      suspense: true
    },
    mutations: {
      networkMode: 'always'
    }
  }
});

function App(): ReactElement {
  return (
    <>
      <CssBaseline />
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <Suspense fallback={<AppLoading />}>
            <BackendClientContextProvider>
              <UserContextProvider>
                <ConfirmDialogContextProvider>
                  <Box sx={{mb: 2}}>
                    <PemsaHeader />
                  </Box>
                  <Container>
                    <Suspense
                      fallback={
                        <Box>
                          <Skeleton
                            variant="rectangular"
                            height={100}
                            sx={{
                              borderRadius: 1
                            }}
                          />
                          <Skeleton
                            variant="rectangular"
                            height={400}
                            sx={{
                              borderRadius: 1
                            }}
                          />
                        </Box>
                      }
                    >
                      <Calculator />
                    </Suspense>
                  </Container>
                </ConfirmDialogContextProvider>
              </UserContextProvider>
            </BackendClientContextProvider>
          </Suspense>
        </ThemeProvider>
      </QueryClientProvider>
    </>
  );
}

export default App;
