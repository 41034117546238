import {ReactElement} from 'react';
import SearchIcon from '@mui/icons-material/Search';
import {Button, Typography} from '@mui/material';
import {Box} from '@mui/system';
import {useTranslation} from 'react-i18next';
import {LoadingBox, LoadingIcon, LoadingText} from 'components/shared/ui/LoadingIndicator';
import {ApiQueryStatus} from 'interfaces/ApiRequestStatus';
import ApiResponse from 'interfaces/ApiResponse';
import {Section, Tray} from 'interfaces/Project';
import TraysTable from './components/TraysTable';

type Props = {
  getTraysRequestStatus: ApiQueryStatus;
  getTraysResponse: ApiResponse<Tray[]> | undefined;
  onSearch: () => void;
  onSelectTray: (tray: Tray) => void;
  section: Section;
  hasFinish: boolean;
};

export default function FindTraysView({
  getTraysRequestStatus,
  getTraysResponse,
  onSearch,
  onSelectTray,
  section,
  hasFinish,
}: Props): ReactElement {
  const {t} = useTranslation('common');
  const hasCables = section.cables.length > 0;

  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mb: 2
        }}
      >
        <Box
          sx={{
            minWidth: {
              xs: '100%',
              lg: 'auto'
            },
            textAlign: 'right'
          }}
        >
          <Button
            onClick={onSearch}
            endIcon={<SearchIcon />}
            color="secondary"
            variant="contained"
            disabled={(hasFinish && !section.finishId) || !hasCables}
            sx={{
              minWidth: {
                xs: '100%',
                lg: 'auto'
              }
            }}
          >
            {t('common:searchTrays')}
          </Button>

          {(hasFinish && !section.finishId) && (
            <Typography variant="body2" sx={{mt: 1, color: 'secondary.main'}}>
              {t('common:selectFinishToSearchTrays')}
            </Typography>
          )}
          {!hasCables && (
            <Typography variant="body2" sx={{mt: 1, color: 'secondary.main'}}>
              {t('common:selectCablesToSearchTrays')}
            </Typography>
          )}
        </Box>
      </Box>
      {getTraysRequestStatus.fetchStatus.isFetching && (
        <LoadingBox>
          <LoadingIcon />
          <LoadingText text={t('common:searchingTrays')} />
        </LoadingBox>
      )}
      {getTraysRequestStatus.dataStatus.isSuccess &&
        getTraysResponse &&
        getTraysResponse.data.length > 0 && (
          <TraysTable trays={getTraysResponse.data} onSelectTray={onSelectTray} />
        )}
      {getTraysRequestStatus.dataStatus.isSuccess &&
        getTraysResponse &&
        getTraysResponse.data.length === 0 && <Typography>{t('common:searchNoTrays')}</Typography>}
    </div>
  );
}
