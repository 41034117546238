/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {ReactElement, ReactNode, useEffect} from 'react';
import ApiSuspense from 'components/shared/ApiSuspense';
import {USER} from 'const/backend';
import useApiQuery from 'hooks/useApiQuery';
import userLocaleLocalStorage from 'utils/userLocaleLocalStorage';
import UserContext, {User} from './UserContext';

type Props = {
  children: ReactNode;
};

export default function UserContextProvider({children}: Props): ReactElement {
  const {apiQueryStatus, apiResponse: userResponse} = useApiQuery<User>({
    queryOptions: {
      queryKey: [USER]
    },
    requestOptions: {
      method: 'GET',
      path: USER
    }
  });

  useEffect(
    function () {
      if (userResponse) {
        userLocaleLocalStorage.set(userResponse.data.locale);
      }
    },
    [userResponse]
  );

  return (
    <ApiSuspense apiQueryStatus={[apiQueryStatus]}>
      {() => <UserContext.Provider value={userResponse!.data}>{children}</UserContext.Provider>}
    </ApiSuspense>
  );
}
