import AuthTokens from 'interfaces/AuthTokens';
import localStorage from 'utils/localStorage';

const PEMSA_PRO_AUTH_TOKENS_KEY = process.env.REACT_APP_PEMSA_PRO_AUTH_TOKENS_KEY;

if (!PEMSA_PRO_AUTH_TOKENS_KEY) {
  throw new Error('No key for local storage of Pemsa Pro Auth Tokens');
}

function createAuthTokensLocalStorage(authTokensKey: string) {
  return {
    exists: () => !!localStorage.get(authTokensKey),
    get: function (): AuthTokens | null {
      const tokens = localStorage.get(authTokensKey);
      if (tokens) {
        return JSON.parse(tokens);
      }
      return null;
    },
    set: (tokens: AuthTokens) => localStorage.set(authTokensKey, JSON.stringify(tokens)),
    delete: () => localStorage.delete(authTokensKey)
  };
}

const authTokensLocalStorage = createAuthTokensLocalStorage(PEMSA_PRO_AUTH_TOKENS_KEY);

export default authTokensLocalStorage;
