import {ReactElement} from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Box, Typography} from '@mui/material';

type Props = {
  text: string;
  href?: string;
  onClick: () => void;
};

export default function TextElement({text, href, onClick}: Props): ReactElement {
  return (
    <Box
      onClick={onClick}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: 'pointer'
      }}
    >
      <Typography
        sx={{
          color: 'text.primary',
          textDecoration: 'none'
        }}
        component={href ? 'a' : 'span'}
        href={href}
      >
        {text}
      </Typography>
      {!href && (
        <ExpandMoreIcon
          sx={{
            color: {
              xs: 'primary.main',
              md: 'grey.300'
            }
          }}
        />
      )}
    </Box>
  );
}
