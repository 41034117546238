import ApiResponse from 'interfaces/ApiResponse';

export default function createApiResponse<TData>(
  isSuccessfull: boolean,
  data: TData,
  status: number | null
): ApiResponse<TData> {
  return {
    isSuccessfull,
    data,
    status
  };
}

export function createSuccessFullResponse<TData>(
  data: TData,
  status: number | null
): ApiResponse<TData> {
  return createApiResponse(true, data, status);
}
