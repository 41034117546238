import {MouseEvent, ReactElement, useState} from 'react';
import useModalState from 'hooks/useModalState';
import {Section, SectionId} from 'interfaces/Project';
import CopyCablesToSectionView from './CopyCablesToSectionView';

type Props = {
  onCablesCopy: (to: SectionId) => void;
  sections: Section[];
};

export default function CopyCablesToSection({onCablesCopy, sections}: Props): ReactElement {
  const {isOpened, open, close} = useModalState(false);
  const [snackbarOpened, setSnackbarOpened] = useState(false);
  const [selectedSectionId, setSelectedSectionId] = useState('');

  function handleCopy() {
    onCablesCopy(selectedSectionId);
    setSnackbarOpened(true);
    close();
  }

  function closeSnackbar() {
    setSnackbarOpened(false);
  }

  function handleSectionSelected(sectionId: string) {
    setSelectedSectionId(sectionId);
  }

  function handleOpen(event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();
    open();
  }

  function handleClose(event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();
    close();
  }

  return (
    <CopyCablesToSectionView
      selectedSectionId={selectedSectionId}
      sections={sections}
      snackbarOpened={snackbarOpened}
      opened={isOpened}
      onSnackbarClose={closeSnackbar}
      onOpenClick={handleOpen}
      onClose={close}
      onCancelClick={handleClose}
      onCopyClick={handleCopy}
      onSectionSelect={handleSectionSelected}
    />
  );
}
