import {createContext} from 'react';

export type OnConfirm = () => void;
export type OnCancel = () => void;
export type ConfirmDialogContextValueArguments = {
  title: string;
  text: string;
  onConfirm?: OnConfirm;
  onCancel?: OnCancel;
};

export type ConfirmDialogContextValue = (
  confirmDialogContextValueArguments: ConfirmDialogContextValueArguments
) => void;

const ConfirmDialogContext = createContext<ConfirmDialogContextValue>(() => {});

export default ConfirmDialogContext;
