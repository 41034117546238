import {createContext, ReactElement, ReactNode, useEffect, useMemo, useRef} from 'react';
import {Alert} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {useMutation} from 'react-query';
import ExpiredJwtError from 'classes/ExpiredJwtError';
import AppLoading from 'components/shared/ui/AppLoading';
import {AUTHENTICATE, BACKEND_URL, REFRESH_TOKEN} from 'const/backend';
import useQueryParams from 'hooks/useQueryParams';
import ApiResponse from 'interfaces/ApiResponse';
import AuthTokens from 'interfaces/AuthTokens';
import ApiError from './ApiError';
import {
  BackendClient,
  RefreshTokenObserver,
  RequestOptions,
  UnsubscribeRefreshTokenObserver
} from './types';
import authTokensLocalStorage from './utils/authTokensLocalStorage';
import {createSuccessFullResponse} from './utils/createApiResponse';
import makeRequest from './utils/makeRequest';

export const defaultValue = Symbol('User Context default value');
type DefaultValue = symbol;

const BackendClientContext = createContext<BackendClient | DefaultValue>(defaultValue);

export default BackendClientContext;

type BackendClientContextProviderProps = {
  children: ReactNode;
};

export function BackendClientContextProvider({
  children
}: BackendClientContextProviderProps): ReactElement {
  const queryParams = useQueryParams();
  const wordpressId = queryParams.get('userId');
  const initialToken = queryParams.get('token');
  const initialRefreshToken = queryParams.get('refresh_token');
  const mode = queryParams.get('mode') ?? '';

  const {t} = useTranslation(['common']);

  const tokens = useRef<AuthTokens | null>(
    initialToken !== null && initialRefreshToken !== null
      ? {token: initialToken, refreshToken: initialRefreshToken}
      : null
  );

  const {mutate, isError} = useMutation(
    async function () {
      const response = await fetch(`${BACKEND_URL}${AUTHENTICATE}?mode=${mode}`, {
        method: 'POST',
        body: JSON.stringify({wordpressId}),
        headers: {
          'Content-Type': 'application/json'
        }
      });
      return response.json();
    },
    {
      onSuccess: function (response) {
        authTokensLocalStorage.set(response.data);
        tokens.current = response.data;
      }
    }
  );

  useEffect(
    function () {
      const currentTokens = tokens.current;
      if (currentTokens) {
        authTokensLocalStorage.set(currentTokens);
      } else {
        mutate();
      }
    },
    [mutate]
  );

  const value = useMemo(
    function () {
      const backendClient = {
        setAuthTokens: function (authTokens: AuthTokens | null): void {
          if (authTokens === null) {
            authTokensLocalStorage.delete();
          } else {
            tokens.current = authTokens;
            authTokensLocalStorage.set(authTokens);
          }
        },
        hasAuthTokens: function (): boolean {
          return Boolean(tokens.current);
        },
        isRefreshingToken: false,
        refreshToken: async function (): Promise<void> {
          if (tokens.current === null) {
            backendClient.refreshTokenObservers.forEach((fn) => fn(false));
            return;
          }
          backendClient.isRefreshingToken = true;
          const request = makeRequest(
            'POST',
            `${BACKEND_URL}${REFRESH_TOKEN}`,
            {
              refreshToken: tokens.current.refreshToken
            },
            false,
            undefined,
            undefined,
            mode
          );
          const response = await backendClient.appFetch(request);
          backendClient.isRefreshingToken = false;
          if (!response.ok || response.status !== 200) {
            backendClient.refreshTokenObservers.forEach((fn) => fn(false));
            return;
          }
          const json = await response.json();
          backendClient.setAuthTokens(json.data);
          backendClient.refreshTokenObservers.forEach((fn) => fn(true));
        },
        requestRefreshToken: function (
          refreshTokenObserver: RefreshTokenObserver
        ): UnsubscribeRefreshTokenObserver {
          backendClient.refreshTokenObservers = [
            ...backendClient.refreshTokenObservers,
            refreshTokenObserver
          ];
          if (!backendClient.isRefreshingToken) {
            backendClient.refreshToken();
          }
          return () =>
            (backendClient.refreshTokenObservers = backendClient.refreshTokenObservers.filter(
              (o) => refreshTokenObserver !== o
            ));
        },
        refreshTokenObservers: [] as RefreshTokenObserver[],
        appFetch: async function (request: Request): Promise<Response> {
          return fetch(request);
        },
        sendRequest: async function <TData, TError = any>({
          method,
          path,
          body = null,
          isMultipart = false,
          headers = {},
          credentials,
          responseIsBlob
        }: RequestOptions): Promise<ApiResponse<TData>> {
          const requestHeaders = new Headers(headers);
          if (tokens.current?.token) {
            requestHeaders.append('Authorization', `Bearer ${tokens.current.token}`);
          }
          const request = makeRequest(
            method,
            `${BACKEND_URL}${path}`,
            body,
            isMultipart,
            requestHeaders,
            credentials,
            mode
          );
          try {
            const response = await backendClient.appFetch(request);
            if (response.status === 204) {
              return createSuccessFullResponse({} as TData, response.status);
            }
            const responseData = responseIsBlob ? await response.blob() : await response.json();

            if (response.status === 401) {
              if (responseData.message === 'Expired JWT Token') {
                throw new ExpiredJwtError('Unauthorized');
              }
            }
            if (response.ok) {
              return createSuccessFullResponse(
                responseIsBlob ? responseData : responseData.data,
                response.status
              );
            } else {
              throw new ApiError<TError>(
                responseData?.message,
                responseData?.errorCode,
                responseData?.data
              );
            }
          } catch (error) {
            if (error instanceof ExpiredJwtError) {
              throw error;
            } else if (error instanceof ApiError) {
              throw error;
            }
            throw new ApiError<TError>(
              error instanceof Error ? error.message : 'Unknown error',
              null,
              null
            );
          }
        }
      };
      return backendClient;
    },
    [mode]
  );

  if (isError) {
    return <Alert severity="error">{t('common:errorLoadingUser')}</Alert>;
  }

  if (!tokens.current) {
    return <AppLoading />;
  }

  return <BackendClientContext.Provider value={value}>{children}</BackendClientContext.Provider>;
}
