import {ReactElement, useState} from 'react';
import AddIcon from '@mui/icons-material/AddCircle';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {Tray} from 'interfaces/Project';
import ShowTray from './ShowTray';

type Props = {
  trays: Tray[];
  onSelectTray: (tray: Tray) => void;
};

export default function TraysTable({trays, onSelectTray}: Props): ReactElement {
  const {t} = useTranslation('common');
  const flangesNames = Array.from(new Set(trays.map((tray) => `${tray.flange.name}`))).sort(
    (a, b) => parseFloat(a) - parseFloat(b)
  );
  const [selectedFlange, setSelectedFlange] = useState(flangesNames[0] ?? '');
  const [selectedTray, setSelectedTray] = useState<Tray | null>(null);

  function handleFlangechange(event: SelectChangeEvent) {
    setSelectedFlange(event.target.value);
  }

  function showTray(tray: Tray) {
    setSelectedTray(tray);
  }

  function closeModal() {
    setSelectedTray(null);
  }

  function selectTray() {
    if (selectedTray) {
      onSelectTray(selectedTray);
      closeModal();
    }
  }

  return (
    <Box>
      <Typography paragraph variant="h5">
        {t('common:foundTrays')}
      </Typography>
      <FormControl fullWidth sx={{mb: 2, mt: 2}}>
        <InputLabel id="flange-select-label">{t('common:selectFlange')}</InputLabel>
        <Select
          labelId="flange-select-label"
          id="flange-select"
          value={selectedFlange}
          label={t('common:selectFlange')}
          onChange={handleFlangechange}
        >
          {flangesNames.map((flangeName) => (
            <MenuItem value={`${flangeName}`} key={`flange--${flangeName}`}>
              {flangeName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Box sx={{mb: 1}}></Box>
      <TableContainer sx={{borderColor: 'borderColor', borderWidth: 1, borderStyle: 'solid'}}>
        <Table aria-label={t('common:selectTray')}>
          <TableHead sx={{bgcolor: 'grey.100'}}>
            <TableRow>
              <TableCell>{t('common:reference')}</TableCell>
              <TableCell>{t('common:description')}</TableCell>
              <TableCell>{t('common:actions')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {trays
              .filter((tray) => `${tray.flange.name}` === selectedFlange)
              .map((tray, index) => (
                <TableRow
                  key={tray.id}
                  sx={{
                    bgcolor: index % 2 ? 'grey.100' : 'white',
                    cursor: 'pointer',
                    '&:last-child td, &:last-child th': {border: 0}
                  }}
                  onClick={() => showTray(tray)}
                >
                  <TableCell>{tray.clientReference}</TableCell>
                  <TableCell>{tray.name}</TableCell>
                  <TableCell>
                    <AddIcon color="primary" />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {selectedTray && (
        <ShowTray tray={selectedTray} onCancel={closeModal} onConfirm={selectTray} />
      )}
    </Box>
  );
}
