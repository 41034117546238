import enFlag from './en.png';
import esFlag from './es.png';
import frFlag from './fr.png';
import ptFlag from './pt.png';

export enum Locale {
  Spanish = 'es',
  English = 'en',
  French = 'fr',
  Portuguese = 'pt'
}

export const LOCALES = [
  {
    id: Locale.Spanish,
    name: 'Español',
    flag: esFlag
  },
  {
    id: Locale.English,
    name: 'English',
    flag: enFlag
  },
  {
    id: Locale.French,
    name: 'French',
    flag: frFlag
  },
  {
    id: Locale.Portuguese,
    name: 'Português',
    flag: ptFlag
  }
];

export const ENABLED_LOCALES = [Locale.Spanish, Locale.English, Locale.French, Locale.Portuguese];
