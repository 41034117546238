import {ChangeEvent, FormEvent, MouseEvent, ReactElement, useState} from 'react';
import {CABLE, CABLES} from 'const/backend';
import useApiMutation from 'hooks/useApiMutation';
import {Cable} from 'interfaces/Project';
import generatePathWithQueryParams from 'utils/generatePathWithQueryParams';
import CableFormView from './CableFormView';
import {Errors} from './types';

type Props = {
  onGoBack: () => void;
  onSuccess: (createdCable: Cable) => void;
  cable: Cable | null;
};

export default function CableForm({onGoBack, onSuccess, cable}: Props): ReactElement {
  const [form, setForm] = useState({
    manufacturer: cable?.manufacturer ?? '',
    name: cable?.name ?? '',
    brand: cable?.brand ?? '',
    conductingSection: cable?.conductingSection ?? '',
    diameter: cable ? `${cable.diameter}` : '',
    weight: cable ? `${cable.weight}` : ''
  });
  const [errors, setErrors] = useState<Errors>({
    manufacturer: null,
    name: null,
    brand: null,
    conductingSection: null,
    diameter: null,
    weight: null
  });
  const {mutate: createCable, apiMutationStatus: createCableRequestStatus} =
    useApiMutation<Cable>();

  function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    createCable(
      {
        method: cable ? 'PUT' : 'POST',
        path: cable ? generatePathWithQueryParams(CABLE, {id: `${cable.id}`}) : CABLES,
        body: {...form}
      },
      {
        onSuccess: function (response) {
          onSuccess(response.data);
        }
      }
    );
  }

  function handleInputChange(event: ChangeEvent<HTMLInputElement>) {
    setForm({
      ...form,
      [event.target.name]: event.target.value
    });
  }

  function handleNumberInputChange(event: ChangeEvent<HTMLInputElement>) {
    const rightNumber = event.target.value.replace(',', '.');
    const number = Number(rightNumber);

    const isError = event.target.value !== '' && isNaN(number);
    if (isError) {
      setErrors((e) => ({
        ...e,
        [event.target.name]: 'invalidNumber'
      }));
    } else {
      setForm({
        ...form,
        [event.target.name]: event.target.value.replace('.', ',')
      });
      setErrors((e) => ({
        ...e,
        [event.target.name]: null
      }));
    }
  }

  function handleGoBack(event: MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    onGoBack();
  }

  return (
    <CableFormView
      form={form}
      errors={errors}
      onSubmit={handleSubmit}
      onGoBack={handleGoBack}
      onInputChange={handleInputChange}
      onNumberInputChange={handleNumberInputChange}
      createCableRequestStatus={createCableRequestStatus}
      isEditing={cable !== null}
    />
  );
}
