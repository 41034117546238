import {ChangeEvent, ReactElement, useRef, useState} from 'react';
import {InputAdornment, TextField} from '@mui/material';
import {TFunction} from 'react-i18next';
import useConfirmDialogContext from 'contexts/ConfirmDialogContext/useConfirmDialogContext';

type Props = {
  initialValue: number | null;
  t: TFunction;
  onChange: (value: number) => void;
  hasSelectedTray: boolean;
  deleteTrayFromSection: () => void;
};

export default function SectionDistance({
  t,
  initialValue,
  onChange,
  hasSelectedTray,
  deleteTrayFromSection
}: Props): ReactElement {
  const [value, setValue] = useState(`${initialValue}`.replace('.', ','));
  const [error, setError] = useState(false);
  const shouldShowConfirmDialog = useRef(hasSelectedTray);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const openConfirmDialog = useConfirmDialogContext();

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const rightNumber = event.target.value.replace(',', '.');
    const number = Number(rightNumber);

    const error = event.target.value !== '' && (isNaN(number) || number < 1 || number > 2);

    setError(error);
    if (!error) {
      setValue(event.target.value.replace('.', ','));
      onChange(number);
    }
  }

  function onMouseDown() {
    if (shouldShowConfirmDialog.current) {
      openConfirmDialog({
        title: t('common:confirmResetTray.title'),
        text: t('common:confirmResetTray.text'),
        onConfirm: function () {
          shouldShowConfirmDialog.current = false;
          setTimeout(() => inputRef.current?.focus(), 0);
          deleteTrayFromSection();
        }
      });
    }
  }

  return (
    <>
      <TextField
        id="sectionDistance"
        label={t('common:supportsDistance.label')}
        helperText={error ? t('common:supportsDistance.error') : t('common:supportsDistance.help')}
        error={error}
        value={value}
        InputProps={{
          endAdornment: <InputAdornment position="end">m</InputAdornment>
        }}
        onChange={handleChange}
        // inputProps={{
        //   step: '0.01',
        //   max: '2',
        //   inputMode: 'numeric',
        //   pattern: '[0-9]*'
        // }}
        fullWidth
        onMouseDown={onMouseDown}
        inputRef={inputRef}
      />
    </>
  );
}
