import {ReactElement} from 'react';
import {Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';

type Props = {
  text?: string;
  textClassName?: string;
  progressSize?: number;
};

function LoadingText({text, textClassName}: Props): ReactElement {
  const {t} = useTranslation(['common']);
  return (
    <Typography className={textClassName} ml={2}>
      {text ?? t('common:loading')}
    </Typography>
  );
}

export default LoadingText;
