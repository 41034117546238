import {ChangeEvent, ReactElement, Suspense, useState} from 'react';
import {AccordionDetails, Box, Grid, Skeleton, TextField, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import PemsaIcon from 'components/shared/ui/PemsaIcon';
import {FinishId} from 'interfaces/Finish';
import {
  Cable,
  CablesPlacement as ICablesPlacement,
  ExtensionType,
  Section as SectionInterface,
  SectionCableId,
  SectionId,
  Tray as ITray
} from 'interfaces/Project';
import {System} from 'interfaces/System';
import Accordion from './components/Accordion';
import AccordionSummary from './components/AccordionSummary';
import CablesPlacement from './components/CablesPlacement';
import CopyCablesToSection from './components/CopyCablesToSection';
import FindTrays from './components/FindTrays';
import SectionBox from './components/SectionBox';
import SectionCables from './components/SectionCables';
import SectionDistance from './components/SectionDistance';
import SectionExtension from './components/SectionExtension/SectionExtension';
import SectionLength from './components/SectionLength';
import SelectFinish from './components/SelectFinish';
import SelectSystem from './components/SelectSystem';
import Tray from './components/Tray';

type Props = {
  section: SectionInterface;
  otherSections: SectionInterface[];
  systems: System[];
  onSystemChange: (systemId: number) => void;
  onFinishChange: (finishId: FinishId) => void;
  onCableSelect: (cable: Cable) => void;
  onCablesCopy: (to: SectionId) => void;
  onLengthChange: (length: number) => void;
  onDistanceChange: (distance: number) => void;
  onExtensionChange: (extension: number) => void;
  onExtensionTypeChange: (type: ExtensionType) => void;
  onNameChange: (name: string) => void;
  onNotesChange: (notes: string) => void;
  onTraySelect: (tray: ITray) => void;
  onCableUnitsChange: (sectionCableId: SectionCableId, units: number) => void;
  onCableDelete: (sectionCableId: SectionCableId) => void;
  onCablesPlacementChange: (placement: ICablesPlacement) => void;
  deleteTrayFromSection: () => void;
};

export default function SectionView({
  section,
  otherSections,
  systems,
  onSystemChange,
  onFinishChange,
  onCableSelect,
  onCablesCopy,
  onLengthChange,
  onDistanceChange,
  onExtensionChange,
  onExtensionTypeChange,
  onNameChange,
  onNotesChange,
  onTraySelect,
  onCableUnitsChange,
  onCableDelete,
  onCablesPlacementChange,
  deleteTrayFromSection,
}: Props): ReactElement {
  const {t} = useTranslation('common');

  const [ hasFinish, setHasFinish ] = useState<boolean>(false);

  return (
    <Box>
      <SectionBox>
        <TextField
          id="sectionName"
          label={t('common:name')}
          value={section.name}
          onChange={(event: ChangeEvent<HTMLInputElement>) => onNameChange(event.target.value)}
          fullWidth
        />
      </SectionBox>
      <SectionBox>
        <TextField
          id="sectionNotes"
          label={t('common:notes')}
          value={section.notes}
          onChange={(event: ChangeEvent<HTMLInputElement>) => onNotesChange(event.target.value)}
          multiline
          fullWidth
          rows={5}
        />
      </SectionBox>
      <Accordion elevation={0}>
        <AccordionSummary aria-controls={`cables-content`} id={`cables-header`}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%'
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontWeight: 'normal',
                fontSize: {
                  xs: '1rem',
                  lg: '1.25rem'
                }
              }}
            >
              <PemsaIcon mr={1} icon="ï" />
              {t('common:cablesSelection')}
            </Typography>
            <div>
              <CopyCablesToSection onCablesCopy={onCablesCopy} sections={otherSections} />
            </div>
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{pt: 4}}>
          <SectionBox>
            <SectionCables
              deleteTrayFromSection={deleteTrayFromSection}
              hasSelectedTray={Boolean(section.tray)}
              sectionCables={section.cables}
              onCableUnitsChange={onCableUnitsChange}
              onCableDelete={onCableDelete}
              onCableSelect={onCableSelect}
            />
          </SectionBox>
          <SectionBox>
            <SectionExtension
              initialExtensionValue={section.extension}
              extensionType={section.extensionType}
              t={t}
              onValueChange={onExtensionChange}
              onExtensionTypeChange={onExtensionTypeChange}
              deleteTrayFromSection={deleteTrayFromSection}
              hasSelectedTray={Boolean(section.tray)}
            />
          </SectionBox>
          <SectionBox>
            <CablesPlacement
              placement={section.cablesPlacement}
              deleteTrayFromSection={deleteTrayFromSection}
              hasSelectedTray={Boolean(section.tray)}
              onChange={onCablesPlacementChange}
            />
          </SectionBox>
        </AccordionDetails>
      </Accordion>

      <Accordion elevation={0}>
        <AccordionSummary aria-controls={`cables-content`} id={`cables-header`}>
          <Box>
            <Typography
              variant="h6"
              sx={{
                fontWeight: 'normal',
                fontSize: {
                  xs: '1rem',
                  lg: '1.25rem'
                }
              }}
            >
              <PemsaIcon mr={1} icon="K" />
              {t('common:traySelection')}
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{pt: 4}}>
          <Grid container spacing={4} mb={4}>
            <Grid item xs={12} md={hasFinish ? 6 : 12}>
              <SelectSystem
                systems={systems}
                systemId={section.traySystemId}
                onSystemChange={onSystemChange}
                hasSelectedTray={Boolean(section.tray)}
                deleteTrayFromSection={deleteTrayFromSection}
              />
            </Grid>

            <Grid item xs={12} md={6} style={{ display: hasFinish ? 'grid' : 'none' }}>
              <Suspense
                fallback={<Skeleton height="100%" variant="rectangular" sx={{borderRadius: 1}} />}
              >
                <SelectFinish
                  setHasFinish={setHasFinish}
                  deleteTrayFromSection={deleteTrayFromSection}
                  finishId={section.finishId}
                  hasSelectedTray={Boolean(section.tray)}
                  onFinishChange={onFinishChange}
                  traySystemId={section.traySystemId}
                />
              </Suspense>
            </Grid>

          </Grid>
          <Grid
            container
            spacing={4}
            sx={{
              mb: {
                xs: 2,
                lg: 0
              }
            }}
          >
            <Grid item xs={12} md={6}>
              <SectionLength initialValue={section.length} t={t} onChange={onLengthChange} />
            </Grid>
            <Grid item xs={12} md={6}>
              <SectionDistance
                deleteTrayFromSection={deleteTrayFromSection}
                hasSelectedTray={Boolean(section.tray)}
                initialValue={section.distance}
                onChange={onDistanceChange}
                t={t}
              />
            </Grid>
          </Grid>
          <SectionBox>
            <FindTrays
              hasFinish={hasFinish}
              section={section}
              onSelectTray={onTraySelect}
              onSearch={deleteTrayFromSection}
            />
          </SectionBox>
          {section.tray && <Tray section={section} tray={section.tray} />}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
