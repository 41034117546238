import {ReactElement} from 'react';
import {Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {CableManufacturer} from 'interfaces/Project';

type Props = {
  manufacturers: CableManufacturer[];
  selectedManufacturer: CableManufacturer;
  onManufacturerSelected: (manufacturer: CableManufacturer) => void;
};

export default function SelectManufacturer({
  onManufacturerSelected,
  selectedManufacturer,
  manufacturers
}: Props): ReactElement {
  const {t} = useTranslation();

  function handleChange(event: SelectChangeEvent) {
    onManufacturerSelected({
      name: event.target.value
    });
  }

  return (
    <Box>
      <FormControl fullWidth>
        <InputLabel id="select-manufacturer-label">
          {t('common:selectCableManufacturer')}
        </InputLabel>
        <Select
          labelId="select-manufacturer-label"
          id="select-finish"
          label={t('common:selectCableManufacturer')}
          value={selectedManufacturer.name}
          onChange={handleChange}
        >
          {manufacturers.map((manufacturer) => (
            <MenuItem value={manufacturer.name} key={manufacturer.name}>
              {manufacturer.name === 'userCables' ? t('common:userCables') : manufacturer.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
