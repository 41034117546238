import {PROJECTS} from 'const/backend';
import {ONE_HOUR_IN_MS} from 'const/time';
import useApiQuery, {UseApiQuery} from 'hooks/useApiQuery';
import {Project} from 'interfaces/Project';
import generatePathWithQueryParams from 'utils/generatePathWithQueryParams';

export default function useProjects(): UseApiQuery<Project[]> {
  const path = generatePathWithQueryParams(PROJECTS, {});
  return useApiQuery({
    requestOptions: {
      method: 'GET',
      path: path
    },
    queryOptions: {
      queryKey: [path],
      staleTime: ONE_HOUR_IN_MS
    }
  });
}
