import {ReactElement, useRef, useState} from 'react';
import {Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent} from '@mui/material';
import {useTranslation} from 'react-i18next';
import useConfirmDialogContext from 'contexts/ConfirmDialogContext/useConfirmDialogContext';
import {System} from 'interfaces/System';

type Props = {
  systems: System[];
  systemId?: number;
  onSystemChange: (systemId: number) => void;
  hasSelectedTray: boolean;
  deleteTrayFromSection: () => void;
};

export default function SelectSystem({
  deleteTrayFromSection,
  hasSelectedTray,
  onSystemChange,
  systems,
  systemId
}: Props): ReactElement {
  const {t} = useTranslation('common');
  const [selectedSystemId, setSelectedSystemId] = useState(systemId ? `${systemId}` : '');
  const shouldShowConfirmDialog = useRef(hasSelectedTray);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const openConfirmDialog = useConfirmDialogContext();

  function handleChange(event: SelectChangeEvent) {
    if (shouldShowConfirmDialog.current) {
      openConfirmDialog({
        onConfirm: function () {
          setSelectedSystemId(event.target.value);
          onSystemChange(parseInt(event.target.value));
          shouldShowConfirmDialog.current = false;
          setTimeout(() => inputRef.current?.focus(), 0);
          deleteTrayFromSection();
        },
        title: t('common:confirmResetTray.title'),
        text: t('common:confirmResetTray.text')
      });
    } else {
      setSelectedSystemId(event.target.value);
      onSystemChange(parseInt(event.target.value));
    }
  }

  return (
    <Box>
      <FormControl fullWidth>
        <InputLabel id="select-system-label">{t('common:system')}</InputLabel>
        <Select
          labelId="select-system-label"
          id="select-system"
          label={t('common:system')}
          value={`${selectedSystemId}`}
          onChange={handleChange}
          inputRef={inputRef}
        >
          {systems.map((system) => (
            <MenuItem value={`${system.id}`} key={`system--${system.id}`}>
              {system.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
