import {ReactElement, useEffect, useRef} from 'react';
import {Alert, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent} from '@mui/material';
import {useTranslation} from 'react-i18next';
import useConfirmDialogContext from 'contexts/ConfirmDialogContext/useConfirmDialogContext';
import {FinishId} from 'interfaces/Finish';
import useFinishes from './hooks/useFinishes';

type Props = {
  deleteTrayFromSection: () => void;
  finishId: number | null;
  hasSelectedTray: boolean;
  onFinishChange: (finishId: FinishId) => void;
  setHasFinish: (hasFinish: boolean) => void;
  traySystemId: number;
};

export default function SelectFinish({
  finishId,
  deleteTrayFromSection,
  hasSelectedTray,
  onFinishChange,
  setHasFinish,
  traySystemId
}: Props): ReactElement {
  const {apiQueryStatus: getFinishesApiQueryStatus, apiResponse: getFinishesResponse} =
    useFinishes(traySystemId);
  const {t} = useTranslation('common');
  const shouldShowConfirmDialog = useRef(hasSelectedTray);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const openConfirmDialog = useConfirmDialogContext();

  function handleChange(event: SelectChangeEvent) {
    if (shouldShowConfirmDialog.current) {
      openConfirmDialog({
        onConfirm: function () {
          onFinishChange(parseInt(event.target.value));
          shouldShowConfirmDialog.current = false;
          setTimeout(() => inputRef.current?.focus(), 0);
          deleteTrayFromSection();
        },
        title: t('common:confirmResetTray.title'),
        text: t('common:confirmResetTray.text')
      });
    } else {
      onFinishChange(parseInt(event.target.value));
    }
  }

  if (getFinishesApiQueryStatus.dataStatus.isError || !getFinishesResponse) {
    return <Alert severity="error">{t('common:errorLoadingFinishes')}</Alert>;
  }

  const finishes = getFinishesResponse.data;

  setHasFinish(finishes && finishes.length > 0);

  return (
    <FormControl fullWidth>
      <InputLabel id="select-finish-label">{t('common:finish')}</InputLabel>
      <Select
        labelId="select-finish-label"
        id="select-finish"
        label={t('common:finish')}
        value={`${finishId ?? ''}`}
        onChange={handleChange}
        inputRef={inputRef}
      >
        {finishes.map((finish) => (
          <MenuItem value={`${finish.id}`} key={`finish--${finish.id}`}>
            {finish.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
