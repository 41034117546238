import {useCallback, useEffect, useMemo} from 'react';
import {useImmerReducer} from 'use-immer';
import {v4 as uuidv4} from 'uuid';
import {FinishId} from 'interfaces/Finish';
import {
  Cable,
  CablesPlacement,
  ExtensionType,
  Project,
  Section,
  SectionCableId,
  SectionId,
  Tray
} from 'interfaces/Project';
import {SystemReference} from 'interfaces/System';
import useSaveProject, {UseSaveProject} from './useSaveProject';

enum Actions {
  UpdateProjectName = 'UpdateProjectName',
  UpdateProjectDescription = 'UpdateProjectDescription',
  UpdateSectionName = 'UpdateSectionName',
  UpdateSectionNotes = 'UpdateSectionNotes',
  UpdateSectionTray = 'UpdateSectionTray',
  UpdateSectionTraySystem = 'UpdateSectionTraySystem',
  UpdateSectionFinish = 'UpdateSectionFinish',
  UpdateSectionLength = 'UpdateSectionLength',
  UpdateSectionDistance = 'UpdateSectionDistance',
  UpdateSectionExtension = 'UpdateSectionExtension',
  UpdateSectionExtensionType = 'UpdateSectionExtensionType',
  UpdateCablesPlacement = 'UpdateCablesPlacement',
  CopySectionCablesToSection = 'CopySectionCablesToSection',
  AddCableToSection = 'AddCableToSection',
  DeleteCableFromSection = 'DeleteCableFromSection',
  UpdateCableUnits = 'UpdateCableUnits',
  AddSection = 'AddSection',
  DeleteSection = 'DeleteSection',
  DeleteTrayFromSection = 'DeleteTrayFromSection',
  LoadProject = 'LoadProject'
}

interface LoadProject {
  type: Actions.LoadProject;
  payload: {
    project: Project;
  };
}

interface UpdateProjectName {
  type: Actions.UpdateProjectName;
  payload: {
    name: string;
  };
}

interface UpdateProjectDescription {
  type: Actions.UpdateProjectDescription;
  payload: {
    description: string;
  };
}

interface UpdateSectionName {
  type: Actions.UpdateSectionName;
  payload: {
    sectionId: SectionId;
    name: string;
  };
}

interface UpdateSectionNotes {
  type: Actions.UpdateSectionNotes;
  payload: {
    sectionId: SectionId;
    notes: string;
  };
}

interface UpdateSectionTray {
  type: Actions.UpdateSectionTray;
  payload: {
    sectionId: SectionId;
    tray: Tray;
  };
}

interface AddSection {
  type: Actions.AddSection;
  payload: {
    systemReference: SystemReference;
    name: string;
  };
}

interface DeleteSection {
  type: Actions.DeleteSection;
  payload: {
    sectionId: SectionId;
  };
}

interface UpdateSectionTraySystem {
  type: Actions.UpdateSectionTraySystem;
  payload: {
    sectionId: SectionId;
    traySystemId: SystemReference;
  };
}

interface UpdateSectionFinish {
  type: Actions.UpdateSectionFinish;
  payload: {
    sectionId: SectionId;
    finishId: FinishId;
  };
}

interface UpdateSectionLength {
  type: Actions.UpdateSectionLength;
  payload: {
    sectionId: SectionId;
    length: number;
  };
}

interface UpdateSectionExtension {
  type: Actions.UpdateSectionExtension;
  payload: {
    sectionId: SectionId;
    extension: number;
  };
}

interface UpdateSectionExtensionType {
  type: Actions.UpdateSectionExtensionType;
  payload: {
    sectionId: SectionId;
    extensionType: ExtensionType;
  };
}

interface UpdateSectionDistance {
  type: Actions.UpdateSectionDistance;
  payload: {
    sectionId: SectionId;
    distance: number;
  };
}

interface AddCableToSection {
  type: Actions.AddCableToSection;
  payload: {
    sectionId: SectionId;
    cable: Cable;
  };
}

interface CopySectionCablesToSection {
  type: Actions.CopySectionCablesToSection;
  payload: {
    from: SectionId;
    to: SectionId;
  };
}

interface UpdateCablesPlacement {
  type: Actions.UpdateCablesPlacement;
  payload: {
    sectionId: SectionId;
    placement: CablesPlacement;
  };
}

interface DeleteTrayFromSection {
  type: Actions.DeleteTrayFromSection;
  payload: {
    sectionId: SectionId;
  };
}

interface DeleteCableFromSection {
  type: Actions.DeleteCableFromSection;
  payload: {
    sectionId: SectionId;
    sectionCableId: SectionCableId;
  };
}

interface UpdateCableUnits {
  type: Actions.UpdateCableUnits;
  payload: {
    sectionId: SectionId;
    sectionCableId: SectionCableId;
    units: number;
  };
}

type Action =
  | LoadProject
  | UpdateProjectName
  | UpdateProjectDescription
  | UpdateSectionTraySystem
  | UpdateSectionFinish
  | UpdateSectionName
  | UpdateSectionNotes
  | UpdateSectionTray
  | AddSection
  | DeleteSection
  | DeleteTrayFromSection
  | AddCableToSection
  | CopySectionCablesToSection
  | UpdateCablesPlacement
  | DeleteCableFromSection
  | UpdateSectionLength
  | UpdateSectionDistance
  | UpdateSectionExtension
  | UpdateSectionExtensionType
  | UpdateCableUnits;

const findSection = (project: Project, sectionId: SectionId) =>
  project.sections.findIndex((section) => section.id === sectionId);

const findSectionCable = (section: Section, sectionCableId: SectionCableId) =>
  section.cables.findIndex((cable) => cable.sectionCableId === sectionCableId);

function reducer(draftProject: Project | null, action: Action) {
  if (draftProject === null) {
    if (action.type === Actions.LoadProject) {
      return structuredClone(action.payload.project);
    }
    return void 0;
  }
  switch (action.type) {
    case Actions.LoadProject: {
      return structuredClone(action.payload.project);
    }
    case Actions.UpdateProjectName: {
      draftProject.name = action.payload.name;
      return void 0;
    }
    case Actions.UpdateProjectDescription: {
      draftProject.description = action.payload.description;
      return void 0;
    }
    case Actions.UpdateSectionName: {
      const sectionIndex = findSection(draftProject, action.payload.sectionId);
      draftProject.sections[sectionIndex].name = action.payload.name;
      return void 0;
    }
    case Actions.UpdateSectionNotes: {
      const sectionIndex = findSection(draftProject, action.payload.sectionId);
      draftProject.sections[sectionIndex].notes = action.payload.notes;
      return void 0;
    }
    case Actions.UpdateSectionTray: {
      const sectionIndex = findSection(draftProject, action.payload.sectionId);
      draftProject.sections[sectionIndex].tray = action.payload.tray;
      return void 0;
    }
    case Actions.UpdateSectionLength: {
      const sectionIndex = findSection(draftProject, action.payload.sectionId);
      draftProject.sections[sectionIndex].length = action.payload.length;
      return void 0;
    }
    case Actions.UpdateSectionDistance: {
      const sectionIndex = findSection(draftProject, action.payload.sectionId);
      if (draftProject.sections[sectionIndex].distance !== action.payload.distance) {
        draftProject.sections[sectionIndex].distance = action.payload.distance;
        draftProject.sections[sectionIndex].tray = null;
      }
      return void 0;
    }
    case Actions.UpdateSectionExtension: {
      const sectionIndex = findSection(draftProject, action.payload.sectionId);
      if (draftProject.sections[sectionIndex].extension !== action.payload.extension) {
        draftProject.sections[sectionIndex].extension = action.payload.extension;
        draftProject.sections[sectionIndex].tray = null;
      }
      return void 0;
    }
    case Actions.UpdateSectionExtensionType: {
      const sectionIndex = findSection(draftProject, action.payload.sectionId);
      if (draftProject.sections[sectionIndex].extensionType !== action.payload.extensionType) {
        draftProject.sections[sectionIndex].extensionType = action.payload.extensionType;
        draftProject.sections[sectionIndex].tray = null;
      }
      return void 0;
    }
    case Actions.UpdateSectionTraySystem: {
      const sectionIndex = findSection(draftProject, action.payload.sectionId);
      draftProject.sections[sectionIndex].traySystemId = action.payload.traySystemId;
      draftProject.sections[sectionIndex].finishId = null;
      return void 0;
    }
    case Actions.UpdateSectionFinish: {
      const sectionIndex = findSection(draftProject, action.payload.sectionId);
      draftProject.sections[sectionIndex].finishId = action.payload.finishId;
      return void 0;
    }
    case Actions.DeleteTrayFromSection: {
      const sectionIndex = findSection(draftProject, action.payload.sectionId);
      draftProject.sections[sectionIndex].tray = null;
      return void 0;
    }
    case Actions.AddCableToSection: {
      const sectionIndex = findSection(draftProject, action.payload.sectionId);
      draftProject.sections[sectionIndex].cables.push({
        ...action.payload.cable,
        hasDeprecatedInfo: false,
        units: 1,
        sectionCableId: uuidv4()
      });
      return void 0;
    }
    case Actions.UpdateCablesPlacement: {
      const sectionIndex = findSection(draftProject, action.payload.sectionId);
      draftProject.sections[sectionIndex].cablesPlacement = action.payload.placement;
      return void 0;
    }
    case Actions.CopySectionCablesToSection: {
      const fromIndex = findSection(draftProject, action.payload.from);
      const toIndex = findSection(draftProject, action.payload.to);
      draftProject.sections[fromIndex].cables.forEach((sectionCable) =>
        draftProject.sections[toIndex].cables.push({
          ...sectionCable,
          sectionCableId: uuidv4()
        })
      );
      return void 0;
    }
    case Actions.DeleteCableFromSection: {
      const sectionIndex = findSection(draftProject, action.payload.sectionId);
      const cableIndex = findSectionCable(
        draftProject.sections[sectionIndex],
        action.payload.sectionCableId
      );
      draftProject.sections[sectionIndex].cables.splice(cableIndex, 1);
      return void 0;
    }
    case Actions.UpdateCableUnits: {
      const sectionIndex = findSection(draftProject, action.payload.sectionId);
      const cableIndex = findSectionCable(
        draftProject.sections[sectionIndex],
        action.payload.sectionCableId
      );
      draftProject.sections[sectionIndex].cables[cableIndex].units = action.payload.units;
      return void 0;
    }
    case Actions.DeleteSection: {
      const sectionIndex = findSection(draftProject, action.payload.sectionId);
      draftProject.sections.splice(sectionIndex, 1);
      return void 0;
    }
    case Actions.AddSection: {
      draftProject.sections.push({
        id: uuidv4(),
        cablesPlacement: CablesPlacement.WithLayers,
        notes: '',
        distance: 1.5,
        extension: 10,
        extensionType: ExtensionType.Normal,
        finishId: null,
        length: 3,
        maxHeight: 0,
        name: action.payload.name,
        totalSection: 0,
        tray: null,
        traySystemId: action.payload.systemReference,
        cables: [],
        position: draftProject.sections.length
      });
      return void 0;
    }
  }
}

export type UseConfiguratorActions = {
  markDepecreatedAlertAsView: () => void;
  updateProjectName: (name: string) => void;
  updateProjectDescription: (description: string) => void;
  updateSectionTraySystem: (sectionId: SectionId, traySystemId: SystemReference) => void;
  updateSectionFinish: (sectionId: SectionId, finishId: FinishId) => void;
  updateSectionLength: (sectionId: SectionId, length: number) => void;
  updateSectionDistance: (sectionId: SectionId, distance: number) => void;
  updateSectionExtension: (sectionId: SectionId, extension: number) => void;
  updateSectionExtensionType: (sectionId: SectionId, extensionType: ExtensionType) => void;
  updateSectionName: (sectionId: SectionId, name: string) => void;
  updateSectionNotes: (sectionId: SectionId, notes: string) => void;
  updateSectionTray: (sectionId: SectionId, tray: Tray) => void;
  updateCablesPlacement: (sectionId: SectionId, placement: CablesPlacement) => void;
  addCableToSection: (sectionId: SectionId, cable: Cable) => void;
  updateSectionCableUnits: (
    sectionId: SectionId,
    sectionCableId: SectionCableId,
    units: number
  ) => void;
  deleteCableFromSection: (sectionId: SectionId, sectionCableId: SectionCableId) => void;
  copySectionCablesToSection: (from: SectionId, to: SectionId) => void;
  addSection: (name: string) => void;
  deleteSection: (sectionId: SectionId) => void;
  deleteTrayFromSection: (sectionId: SectionId) => void;
};

type UseConfigurator = [Project | null, UseConfiguratorActions, UseSaveProject];

type Props = {
  project: Project | null;
  defaultSystemReference: SystemReference;
};

export default function useConfigurator({project, defaultSystemReference}: Props): UseConfigurator {
  const [editableProject, dispatch] = useImmerReducer<Project | null, Action>(
    reducer,
    project,
    (project: Project | null) => (project ? structuredClone(project) : null)
  );
  const saveProject = useSaveProject();

  useEffect(
    function () {
      if (project !== null) {
        dispatch({
          type: Actions.LoadProject,
          payload: {
            project
          }
        });
      }
    },
    [project, dispatch]
  );

  const markDepecreatedAlertAsView = useCallback(
    function () {
      if (editableProject) {
        saveProject.send({
          ...editableProject,
          hasDeprecatedInfo: false
        });
      }
    },
    [editableProject, saveProject]
  );

  const updateProjectName = useCallback(
    function (name: string) {
      dispatch({
        type: Actions.UpdateProjectName,
        payload: {
          name
        }
      });
    },
    [dispatch]
  );

  const updateProjectDescription = useCallback(
    function (description: string) {
      dispatch({
        type: Actions.UpdateProjectDescription,
        payload: {
          description
        }
      });
    },
    [dispatch]
  );

  const updateSectionTraySystem = useCallback(
    function (sectionId: SectionId, traySystemId: SystemReference) {
      dispatch({
        type: Actions.UpdateSectionTraySystem,
        payload: {
          sectionId,
          traySystemId
        }
      });
    },
    [dispatch]
  );

  const updateSectionFinish = useCallback(
    function (sectionId: SectionId, finishId: FinishId) {
      dispatch({
        type: Actions.UpdateSectionFinish,
        payload: {
          sectionId,
          finishId
        }
      });
    },
    [dispatch]
  );

  const updateSectionLength = useCallback(
    function (sectionId: SectionId, length: number) {
      dispatch({
        type: Actions.UpdateSectionLength,
        payload: {
          sectionId,
          length
        }
      });
    },
    [dispatch]
  );

  const updateSectionDistance = useCallback(
    function (sectionId: SectionId, distance: number) {
      dispatch({
        type: Actions.UpdateSectionDistance,
        payload: {
          sectionId,
          distance
        }
      });
    },
    [dispatch]
  );

  const updateSectionExtension = useCallback(
    function (sectionId: SectionId, extension: number) {
      dispatch({
        type: Actions.UpdateSectionExtension,
        payload: {
          sectionId,
          extension
        }
      });
    },
    [dispatch]
  );

  const updateSectionExtensionType = useCallback(
    function (sectionId: SectionId, extensionType: ExtensionType) {
      dispatch({
        type: Actions.UpdateSectionExtensionType,
        payload: {
          sectionId,
          extensionType
        }
      });
    },
    [dispatch]
  );

  const updateSectionName = useCallback(
    function (sectionId: SectionId, name: string) {
      dispatch({
        type: Actions.UpdateSectionName,
        payload: {
          sectionId,
          name
        }
      });
    },
    [dispatch]
  );

  const updateSectionNotes = useCallback(
    function (sectionId: SectionId, notes: string) {
      dispatch({
        type: Actions.UpdateSectionNotes,
        payload: {
          sectionId,
          notes
        }
      });
    },
    [dispatch]
  );

  const updateSectionTray = useCallback(
    function (sectionId: SectionId, tray: Tray) {
      dispatch({
        type: Actions.UpdateSectionTray,
        payload: {
          sectionId,
          tray
        }
      });
    },
    [dispatch]
  );

  const addCableToSection = useCallback(
    function (sectionId: SectionId, cable: Cable) {
      dispatch({
        type: Actions.AddCableToSection,
        payload: {
          sectionId,
          cable
        }
      });
    },
    [dispatch]
  );

  const copySectionCablesToSection = useCallback(
    function (from: SectionId, to: SectionId) {
      dispatch({
        type: Actions.CopySectionCablesToSection,
        payload: {
          from,
          to
        }
      });
    },
    [dispatch]
  );

  const updateCablesPlacement = useCallback(
    function (sectionId: SectionId, placement: CablesPlacement) {
      dispatch({
        type: Actions.UpdateCablesPlacement,
        payload: {
          sectionId,
          placement
        }
      });
    },
    [dispatch]
  );

  const updateSectionCableUnits = useCallback(
    function (sectionId: SectionId, sectionCableId: SectionCableId, units: number) {
      dispatch({
        type: Actions.UpdateCableUnits,
        payload: {
          sectionId,
          sectionCableId,
          units
        }
      });
    },
    [dispatch]
  );

  const deleteCableFromSection = useCallback(
    function (sectionId: SectionId, sectionCableId: SectionCableId) {
      dispatch({
        type: Actions.DeleteCableFromSection,
        payload: {
          sectionId,
          sectionCableId
        }
      });
    },
    [dispatch]
  );

  const addSection = useCallback(
    function (name: string) {
      dispatch({
        type: Actions.AddSection,
        payload: {
          systemReference: defaultSystemReference,
          name
        }
      });
    },
    [dispatch, defaultSystemReference]
  );

  const deleteSection = useCallback(
    function (sectionId: SectionId) {
      dispatch({
        type: Actions.DeleteSection,
        payload: {
          sectionId
        }
      });
    },
    [dispatch]
  );

  const deleteTrayFromSection = useCallback(
    function (sectionId: SectionId) {
      dispatch({
        type: Actions.DeleteTrayFromSection,
        payload: {
          sectionId
        }
      });
    },
    [dispatch]
  );

  const actions = useMemo(
    () => ({
      markDepecreatedAlertAsView,
      updateProjectName,
      updateProjectDescription,
      updateSectionTraySystem,
      updateSectionFinish,
      updateSectionLength,
      updateSectionDistance,
      updateSectionExtension,
      updateSectionExtensionType,
      updateSectionName,
      updateSectionNotes,
      updateSectionTray,
      updateCablesPlacement,
      copySectionCablesToSection,
      addCableToSection,
      updateSectionCableUnits,
      deleteCableFromSection,
      addSection,
      deleteSection,
      deleteTrayFromSection
    }),
    [
      markDepecreatedAlertAsView,
      updateProjectName,
      updateProjectDescription,
      updateSectionTraySystem,
      updateSectionFinish,
      updateSectionLength,
      updateSectionDistance,
      updateSectionExtension,
      updateSectionExtensionType,
      updateSectionName,
      updateSectionNotes,
      updateSectionTray,
      updateCablesPlacement,
      copySectionCablesToSection,
      addCableToSection,
      updateSectionCableUnits,
      deleteCableFromSection,
      addSection,
      deleteSection,
      deleteTrayFromSection
    ]
  );

  return [editableProject, actions, saveProject];
}
