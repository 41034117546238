import localStorage from 'utils/localStorage';

const PEMSA_PRO_LOCALE_KEY = 'PEMSA_PRO_LOCALE';

function createUserLocaleLocalStorage() {
  return {
    isStored: () => !!localStorage.get(PEMSA_PRO_LOCALE_KEY),
    get: () => localStorage.get(PEMSA_PRO_LOCALE_KEY),
    set: (userLocale: string) => localStorage.set(PEMSA_PRO_LOCALE_KEY, userLocale),
    delete: () => localStorage.delete(PEMSA_PRO_LOCALE_KEY)
  };
}

const userLocaleLocalStorage = createUserLocaleLocalStorage();

export default userLocaleLocalStorage;
