import {useCallback, useMemo, useState} from 'react';

interface UseModalState {
  isOpened: boolean;
  open: () => void;
  close: () => void;
}

export default function useModalState(initialValue = false): UseModalState {
  const [isOpened, setIsOpened] = useState(initialValue);

  const open = useCallback(() => setIsOpened(true), []);

  const close = useCallback(() => setIsOpened(false), []);

  return useMemo(
    () => ({
      isOpened,
      open,
      close
    }),
    [isOpened, open, close]
  );
}
