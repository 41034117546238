import {ChangeEvent, ReactElement, useRef, useState} from 'react';
import {TextField} from '@mui/material';
import {TFunction} from 'react-i18next';
import useConfirmDialogContext from 'contexts/ConfirmDialogContext/useConfirmDialogContext';

type Props = {
  deleteTrayFromSection: () => void;
  hasSelectedTray: boolean;
  initialValue: number | null;
  onChange: (value: number) => void;
  t: TFunction;
};

export default function CableUnits({
  deleteTrayFromSection,
  hasSelectedTray,
  initialValue,
  onChange,
  t
}: Props): ReactElement {
  const [value, setValue] = useState(`${initialValue}`);
  const [error, setError] = useState(false);
  const shouldShowConfirmDialog = useRef(hasSelectedTray);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const openConfirmDialog = useConfirmDialogContext();

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    setValue(event.target.value);
    const number = parseInt(event.target.value);
    if (!isNaN(number) && number > 0) {
      onChange(Number(event.target.value));
      setError(false);
    } else {
      setError(true);
    }
  }

  function onMouseDown() {
    if (shouldShowConfirmDialog.current) {
      openConfirmDialog({
        title: t('common:confirmResetTray.title'),
        text: t('common:confirmResetTray.text'),
        onCancel: function () {
          setValue(`${initialValue}`);
        },
        onConfirm: function () {
          shouldShowConfirmDialog.current = false;
          setTimeout(() => inputRef.current?.focus(), 0);
          deleteTrayFromSection();
        }
      });
    }
  }

  return (
    <>
      <TextField
        error={error}
        fullWidth
        helperText={error ? t('common:unitsGreaterThan0') : undefined}
        inputRef={inputRef}
        onChange={handleChange}
        onMouseDown={onMouseDown}
        type="number"
        value={value}
      />
    </>
  );
}
