import {useCallback} from 'react';
import {UseMutateFunction, useMutation, UseMutationOptions} from 'react-query';
import ExpiredJwtError from 'classes/ExpiredJwtError';
import {ApiError, useBackendClient} from 'contexts/BackendClientContext';
import {ApiMutationStatus} from 'interfaces/ApiRequestStatus';
import ApiResponse from 'interfaces/ApiResponse';

const defaultHeaders = {};

type RequestOptions = {
  method: 'GET' | 'POST' | 'DELETE' | 'PUT' | 'PATCH';
  path: string;
  body?: Record<string, any>;
  isMultipart?: boolean;
  headers?: Record<string, string>;
  responseIsBlob?: boolean;
  credentials?: RequestCredentials;
};

export type UseApiMutation<TData, TError> = {
  apiMutationStatus: ApiMutationStatus;
  apiResponse?: ApiResponse<TData> | null;
  mutate: UseMutateFunction<ApiResponse<TData>, TError, RequestOptions, unknown>;
  reset: () => void;
};

type Options<TData, TError, TVariables, TContext> = Omit<
  UseMutationOptions<TData, TError, TVariables, TContext>,
  'mutationFn'
>;

export default function useApiMutation<TData, TError = ApiError>(
  options?: Options<ApiResponse<TData>, any, any, any>
): UseApiMutation<TData, TError> {
  const backendClient = useBackendClient();
  const sendRequest = useCallback(
    async function ({
      method,
      path,
      body,
      isMultipart = false,
      headers = defaultHeaders,
      credentials = 'same-origin',
      responseIsBlob
    }: RequestOptions) {
      async function backendClientSendRequest() {
        return backendClient.sendRequest<TData>({
          method,
          path,
          body,
          isMultipart,
          headers,
          credentials,
          responseIsBlob
        });
      }

      try {
        return backendClientSendRequest();
      } catch (error) {
        if (error instanceof ExpiredJwtError && backendClient.hasAuthTokens()) {
          await backendClient.refreshToken();
          return await backendClientSendRequest();
        }
        throw error;
      }
    },
    [backendClient]
  );

  const {
    mutate,
    isIdle,
    isLoading,
    isError,
    isSuccess,
    data: apiResponse,
    reset
  } = useMutation<ApiResponse<TData>, TError, RequestOptions>(sendRequest, {
    retry: false,
    ...options
  });

  const apiMutationStatus = {
    isIdle,
    isError,
    isSuccess,
    isLoading
  };

  return {apiMutationStatus, apiResponse, mutate, reset};
}
