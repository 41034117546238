/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {ReactElement} from 'react';
import {Section, Tray} from 'interfaces/Project';
import FindTraysView from './FindTraysView';
import useFindTrays from './hooks/useFindTrays';

type Props = {
  section: Section;
  onSelectTray: (tray: Tray) => void;
  onSearch: () => void;
  hasFinish: boolean;
};

export default function FindTrays({section, onSelectTray, onSearch, hasFinish}: Props): ReactElement {
  const {
    apiQueryStatus: getTraysRequestStatus,
    apiResponse: getTraysResponse,
    refetch,
    remove
  } = useFindTrays({
    finishId: section.finishId,
    distance: section.distance,
    cablesPlacement: section.cablesPlacement,
    traySystemId: section.traySystemId,
    extension: section.extension,
    sectionCables: section.cables
  });

  function handleSearch() {
    onSearch();
    refetch();
  }

  function handleSelectTray(tray: Tray) {
    remove();
    onSelectTray(tray);
  }

  return (
    <FindTraysView
      getTraysRequestStatus={getTraysRequestStatus}
      getTraysResponse={getTraysResponse}
      onSearch={handleSearch}
      onSelectTray={handleSelectTray}
      section={section}
      hasFinish={hasFinish}
    />
  );
}
