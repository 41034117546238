import {TRAYS} from 'const/backend';
import {ONE_HOUR_IN_MS} from 'const/time';
import useApiQuery, {UseApiQuery} from 'hooks/useApiQuery';
import {CablesPlacement, SectionCable, Tray} from 'interfaces/Project';
import generatePathWithQueryParams from 'utils/generatePathWithQueryParams';

type Props = {
  finishId: number | null;
  distance: number;
  cablesPlacement: CablesPlacement;
  traySystemId: number;
  extension: number;
  sectionCables: SectionCable[];
};
export default function useFindTrays({
  finishId,
  distance,
  cablesPlacement,
  traySystemId,
  extension,
  sectionCables
}: Props): UseApiQuery<Tray[]> {
  const path = generatePathWithQueryParams(
    TRAYS,
    {},
    {
      finishId: finishId ?? '',
      distance,
      cablesPlacement,
      traySystemId,
      extension,
      sectionCables: sectionCables
        .map((sectionCable) => `${sectionCable.id}-${sectionCable.units}`)
        .join(',')
    }
  );
  return useApiQuery({
    requestOptions: {
      method: 'GET',
      path
    },
    queryOptions: {
      queryKey: [path],
      staleTime: ONE_HOUR_IN_MS,
      enabled: false,
      suspense: false
    }
  });
}
